// could add more brands and then add angle info
export const AudioConfig = {
    klipsch: {
        id: "klipsch",
        name: "Klipsch",
        ceiling: {
            gltf: "assets/models/Klipsch_PRO180RPC001/Klipsch_PRO180RPC_001.gltf",
            // the dimensions of the speaker [w, h, d]
            size: [ 27.3, 11.5, 27.3 ],
            // the offset in cm to the cone [x, y, z]
            speakerOffset: [0, 2, 0],
            // the speakers point down towards the listener
            down: true,
            // the cone angles
            innerAngle: 65,
            outerAngle: 102
        },
        front: {
            gltf: "assets/models/Klipsch_RP8000F001/Klipsch_RP8000F_001.gltf",
            size: [ 27.7, 109.5, 44.6 ],
            speakerOffset: [0, 50, 0],
            innerAngle: 55,
            outerAngle: 86
        },
        center: {
            gltf: "assets/models/Klipsch_RP504C001/Klipsch_RP504C_001.gltf",
            size: [ 79.1, 17.3, 36.7 ],
            speakerOffset: [0, 8.5, 0],
            innerAngle: 55,
            outerAngle: 86
        },
        surround: {
            gltf: "assets/models/Klipsch_RRP600M001/Klipsch_RP600M_001.gltf",
            size: [ 20.2, 39.8, 30.1 ],
            speakerOffset: [0, 10, 0],
            innerAngle: 55,
            outerAngle: 86
        },
        subwoofer: {
            gltf: "assets/models/Klipsch_SPL100001/Klipsch_SPL100_001.gltf",
            size: [ 35.6, 42.1, 45.1 ],
            speakerOffset: [0, 20, 0],
            innerAngle: 55,
            outerAngle: 86
        }
    }
};