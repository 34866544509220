import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useSeatConfig} from "../SeatConfigContext";
import {getAngle} from "../utils/trig";
import {getAngleScore} from "../utils/speakerUtils";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";

// check angle with primary ear to see if it's within the tolerated range
function getAngleWithEarScore(speakerPos, earPos, roomHeight, idealAngle, minAngle, maxAngle)
{
    const z = speakerPos[2] - earPos[2];
    const h = roomHeight - earPos[1];
    const angle = getAngle(h, z);
    return getAngleScore(angle, idealAngle, minAngle, maxAngle);
}

// just make sure there's no intersection with the walls
function getDistanceToWallsScore(width, height, depth, positions, size)
{
    const maxW = size[0] * .5
    const maxD = size[2] * .5
    const maxX = width * .5 - maxW;
    const maxZ = depth - maxD;
    const minZ = maxD;

    for (let i = 0; i < positions.length; ++i) {
        const x = positions[i].position[0];
        const z = -positions[i].position[2];

        if (x > maxX || z > maxZ || z < minZ)
            return 3;
    }

    return 0;
}

function getHeightScore(positions, primaryEarPosition)
{
    const y = positions[0].position[1];

    // Ideally, must be 2 x ear height according to Atmos spec
    if (y < primaryEarPosition[1] * 2)
        return 1;

    return 0;
}

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useCeilingSpeakerValidator = (config, positions) => {
    const {width, height, depth} = useRoomConfig();
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        // use the diagonal as the minimum offset to walls
        const numSpeakers = positions.length;
        const score = createScore();
        if (numSpeakers === 0) return score;

        updateScore(score,
            getDistanceToWallsScore(width, height, depth, positions, config.size),
            ErrorCodes.CeilingSpeakersTooCloseToWalls
        );

        if (numSpeakers === 2) {
            updateScore(score,
                // measure it from the floor so we match the Atmos spec
                getAngleWithEarScore(positions[0].position, primaryEarPosition, height, 80, 65, 100),
                ErrorCodes.CeilingSpeakersWrongAngle
            );
        }
        else if (numSpeakers === 4) {
            updateScore(score,
                getAngleWithEarScore(positions[0].position, primaryEarPosition, height, 55, 30, 55), //@TODO minangle / maxangle
                ErrorCodes.CeilingSpeakersWrongAngleFront
            );
            updateScore(score,
                getAngleWithEarScore(positions[2].position, primaryEarPosition, height, 125, 125, 150), //@TODO minangle / maxangle
                ErrorCodes.CeilingSpeakersWrongAngleRear
            );
        }

        updateScore(score, getHeightScore(positions, primaryEarPosition), ErrorCodes.CeilingSpeakersTooLow);

        return score;
    }, [width, height, depth, config, positions, primaryEarPosition]);
}
