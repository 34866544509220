import SimpleFormItem from "./SimpleFormItem";
import {ScreenConfig} from "../../config/ScreenConfig";
import {AisleMode, FloorColors, useRoomConfig} from "../../model/RoomConfigContext";
import {useSeatConfig} from "../../model/SeatConfigContext";
import {useScreenConfig} from "../../model/ScreenConfigContext";
import {useAudioConfig} from "../../model/AudioConfigContext";
import {useScreenLimits} from "../../hooks/useScreenLimits";
import {CameraMode, useInteractionContext} from "../../model/InteractionContext";
import {Link} from 'react-scroll'

export default function Menu() {
    const {
        width,
        setWidth,
        height,
        setHeight,
        depth,
        setDepth,
        aisleWidth,
        setAisleWidth,
        aisleMode,
        setAisleMode,
        floorColor,
        setFloorColor,
        mainLightOn,
        setMainLightOn,
        floorLedsOn,
        setFloorLedsOn
    } = useRoomConfig();
    const {
        numRows,
        setNumRows,
        numSeats,
        setNumSeats,
        preferredRow,
        setPreferredRow,
        distance,
        setDistance,
        id: seatID,
        setConfigID: setSeatID,
        modelID,
        setModelID,
        models,
        maxAisleWidth,
        primaryEarPosition
    } = useSeatConfig();
    const {
        aspectRatio,
        setAspectRatio,
        screenSize,
        setScreenSize,
        id: screenID,
        setConfigID: setScreenID,
        aspectRatios,
        screenRange,
        screenFloorDistance,
        throwRatioMin,
        throwRatioMax,
        projectorPosition,
    } = useScreenConfig();

    const {setCameraMode} = useInteractionContext();
    const {
        surroundType,
        setSurroundType,
        rearInCeiling,
        setRearInCeiling,
        numCeilingSpeakers,
        showCones,
        setShowCones,
        frontPositions
    } = useAudioConfig();
    const [minScreen, maxScreen] = useScreenLimits(aspectRatio, screenRange, screenFloorDistance);

    function scrollParentToChild(parent, child) {

        document.getElementById('RightInfoContainer').classList.remove("collapsed");
        document.getElementById('collapse').style.display = "block";
        document.getElementById('expand').style.display = "none";

        // Where is the parent on page
        var parentRect = parent.getBoundingClientRect();
        // What can you see?
        var parentViewableArea = {
            height: parent.clientHeight,
            width: parent.clientWidth
        };

        // Where is the child
        var childRect = child.getBoundingClientRect();
        // Is the child viewable?
        var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

        // if you can't see the child try to scroll parent
        if (!isViewable) {
            // Should we scroll using top or bottom? Find the smaller ABS adjustment
            const scrollTop = childRect.top - parentRect.top;
            const scrollBot = childRect.bottom - parentRect.bottom;
            if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                // we're near the top of the list
                parent.scrollTop += scrollTop;
            } else {
                // we're near the bottom of the list
                parent.scrollTop += scrollBot;
            }
        }
    }


    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    function submitData() {
        const configUrl = 'http://cinedream.local/designer/configuration';

        const postBody = {
            width: width,
            height: height,
            depth: depth,
            aisleWidth: aisleWidth,
            aisleMode: aisleMode,
            floorColor: floorColor,
            floorLedsOn: floorLedsOn,
            numRows: numRows,
            numSeats: numSeats,
            preferredRow: preferredRow,
            distance: distance,
            modelID: modelID,
            aspectRatio: aspectRatio,
            screenSize: screenSize,
            screenFloorDistance: screenFloorDistance,
            projectorPosition: projectorPosition,
            surroundType: surroundType,
            rearInCeiling: rearInCeiling,
            numCeilingSpeakers: numCeilingSpeakers,
            frontPositions: frontPositions,
            screenConfig: ScreenConfig,
            email: document.getElementById('mail').value,
            name: document.getElementById('name').value,
            region: document.getElementById('region').value,
            newsletter: document.getElementById('newsletter').checked,
        };

        postData(configUrl, postBody)
            .then(data => {
                console.log(data); // JSON data parsed by `data.json()` call
            });
    }

    return (
        <div>
            <ul>
                <li><a onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('room'))}>1. Ruimte</a></li>
                <li><a onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('video'))}>2. Scherm en projectie</a></li>
                <li><a onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('audio'))}>3. Audio setup</a></li>
                <li><a onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('seats'))}>4. Zitcomfort</a></li>
                <li><a onClick={() => document.getElementById('summaryForm').style.display = "block"}>5. SAMENVATTING</a></li>
            </ul>
            <label className="showcones" data-active={showCones}>Geluidsgolven<input id="showcones" type="checkbox" checked={showCones} onChange={e => setShowCones(e.target.checked)}/></label>
            <label className="light" data-active={mainLightOn}>Licht<input id="light" type="checkbox" checked={mainLightOn} onChange={e => setMainLightOn(e.target.checked)}/></label>
            <div className="camera">
                <div className="inner">
            <button className="camera orbit" data-info={CameraMode} onClick={() => setCameraMode(CameraMode.Orbit)}>Overzicht</button>
            <button className="camera topdown" data-info={CameraMode} onClick={() => setCameraMode(CameraMode.TopDown)}>Bovenzicht</button>
            <button className="camera seat" data-info={CameraMode} onClick={() => setCameraMode(CameraMode.Seat)}>In zetel</button>
                </div>
            </div>
            <div className="summaryForm" id="summaryForm">
                <div class="background"></div>
                <div class="form">
                    <button className="close" onClick={() => document.getElementById('summaryForm').style.display = "none"}>x</button>
                <h3>Samenvatting</h3>
                <p>Je configuratie is klaar om verzonden te worden. Vul uw e-mail adres in en ontvang dadelijk een overzicht met
                    extra informatie.</p>
                    <p>Het e-mail adres is nodig om een unieke link te kunnen versturen waarmee de configuratie opnieuw ingeladen kan worden.</p>
                    <div className="row"><span className="label">Naam</span><SimpleFormItem id="name" type="text"/></div>
                    <div className="row"><span className="label">E-mail adres</span><SimpleFormItem id="mail" type="text"/></div>
                    <div className="row"><span className="label">Regio</span><SimpleFormItem id="region" type="text"/></div>
                    <div className="row"><label><SimpleFormItem type="checkbox" id="newsletter"/> Schrijf je in voor de nieuwsbrief</label></div>
                <div className="row">
                    <button className="submit" onClick={() => submitData()}>Verzenden</button>
                </div>
                </div>
            </div>
        </div>
    );
}
