import {BufferAttribute, BufferGeometry} from "three";

let geom;
export function useLedStripGeometry()
{
    if (!geom) {
        geom = new BufferGeometry();
        const positions = new Float32Array([
            // left
            -0.5, 1.0, -1.0,
            -0.5, 1.0, 0.0,
            -0.5, 0.0, 0.0,
            -0.5, 0.0, -1.0,

            // right
            0.5, 1.0, 0.0,
            0.5, 1.0, -1.0,
            0.5, 0.0, -1.0,
            0.5, 0.0, 0.0,

            // back
            0.5, 1.0, -1.0,
            -0.5, 1.0, -1.0,
            -0.5, 0.0, -1.0,
            0.5, 0.0, -1.0,

            // front
            /*-0.5, 1.0, 0.0,
            0.5, 1.0, 0.0,
            0.5, 0.0, 0.0,
            -0.5, 0.0, 0.0,*/
        ]);

        const uv = new Float32Array([
            // regular quads
            0, 1, 1, 1, 1, 0, 0, 0,
            0, 1, 1, 1, 1, 0, 0, 0,
            0, 1, 1, 1, 1, 0, 0, 0,
            // 0, 1, 1, 1, 1, 0, 0, 0,
        ]);

        const indices = [
            0, 1, 2, 0, 2, 3,
            4, 5, 6, 4, 6, 7,
            8, 9, 10, 8, 10, 11,
            // 12, 13, 14, 12, 14, 15
        ];

        geom.setAttribute("position", new BufferAttribute(positions, 3));
        geom.setAttribute("uv", new BufferAttribute(uv, 2, true));
        geom.setIndex(indices);
    }

    return geom;
}