import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {ScreenConfig} from "../config/ScreenConfig";
import {useProjectorValidator} from "./rules/useProjectorValidator";
import {useProjectorPosition} from "./rules/useProjectorPosition";
import {useScreenSize} from "./rules/useScreenSize";
import {useScreenValidator} from "./rules/useScreenValidator";
import useScreenFloorDistance from "./rules/useScreenFloorDistance";
import {loadedConfig} from "./InteractionContext";
import {AisleMode, FloorColors} from "./RoomConfigContext";

export const ScreenConfigContext = createContext(null);

export default function ScreenConfigProvider({children}) {

    const queryString = window.location.search;
    const UrlParams = new URLSearchParams(queryString);

    const [ config, setConfig ] = useState(ScreenConfig.epson_eh_tw9400);
    const [ aspectRatio, setAspectRatio ] = useState(config.aspectRatios[0]);
    const setConfigID = useCallback(id => setConfig(ScreenConfig[id]), []);
    const [ screenSize, setScreenSize ] = useScreenSize(config, 250);

    const projectorPosition = useProjectorPosition(config, screenSize);
    const screenFloorDistance = useScreenFloorDistance(config, screenSize, aspectRatio, projectorPosition);
    const { score: projectorScore, errorCodes: projectorErrorCodes } = useProjectorValidator(config, screenSize, projectorPosition);
    const { score: screenScore, errorCodes: screenErrorCodes } = useScreenValidator(aspectRatio, config.screenRange, screenFloorDistance, screenSize);

    // when changing configs, make sure current aspect ratio is supported
    useEffect(() => {
        if (config.aspectRatios.indexOf(aspectRatio) < 0)
            setAspectRatio(config.aspectRatios[0]);
    }, [aspectRatio, config]);

    return (
        <ScreenConfigContext.Provider value={{
            screenSize, setScreenSize,
            aspectRatio, setAspectRatio,
            ...config, setConfigID,
            projectorPosition,
            projectorScore, projectorErrorCodes,
            screenScore, screenErrorCodes,
            screenFloorDistance
        }}>
            {children}
        </ScreenConfigContext.Provider>
    );
}

export const useScreenConfig = () => useContext(ScreenConfigContext);
