import SimpleFormItem from "./SimpleFormItem";
import {ScreenConfig} from "../../config/ScreenConfig";
import {AisleMode, FloorColors, useRoomConfig} from "../../model/RoomConfigContext";
import {useSeatConfig} from "../../model/SeatConfigContext";
import {useScreenConfig} from "../../model/ScreenConfigContext";
import {useAudioConfig} from "../../model/AudioConfigContext";
import {useScreenLimits} from "../../hooks/useScreenLimits";
import {CameraMode, useInteractionContext} from "../../model/InteractionContext";

export default function Menu() {
    const {
        width,
        setWidth,
        height,
        setHeight,
        depth,
        setDepth,
        aisleWidth,
        setAisleWidth,
        aisleMode,
        setAisleMode,
        floorColor,
        setFloorColor,
        mainLightOn,
        setMainLightOn,
        floorLedsOn,
        setFloorLedsOn
    } = useRoomConfig();
    const {
        numRows,
        setNumRows,
        numSeats,
        setNumSeats,
        preferredRow,
        setPreferredRow,
        distance,
        setDistance,
        id: seatID,
        setConfigID: setSeatID,
        modelID,
        setModelID,
        models,
        maxAisleWidth,
        primaryEarPosition
    } = useSeatConfig();
    const {
        aspectRatio,
        setAspectRatio,
        screenSize,
        setScreenSize,
        id: screenID,
        setConfigID: setScreenID,
        aspectRatios,
        screenRange,
        screenFloorDistance,
        throwRatioMin,
        throwRatioMax,
        projectorPosition,
    } = useScreenConfig();

    const {setCameraMode} = useInteractionContext();
    const {
        surroundType,
        setSurroundType,
        rearInCeiling,
        setRearInCeiling,
        numCeilingSpeakers,
        showCones,
        setShowCones,
        frontPositions
    } = useAudioConfig();
    const [minScreen, maxScreen] = useScreenLimits(aspectRatio, screenRange, screenFloorDistance);


    function scrollParentToChild(parent, child) {

        // Where is the parent on page
        var parentRect = parent.getBoundingClientRect();
        // What can you see?
        var parentViewableArea = {
            height: parent.clientHeight,
            width: parent.clientWidth
        };

        // Where is the child
        var childRect = child.getBoundingClientRect();
        // Is the child viewable?
        var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

        // if you can't see the child try to scroll parent
        if (!isViewable) {
            // Should we scroll using top or bottom? Find the smaller ABS adjustment
            const scrollTop = childRect.top - parentRect.top;
            const scrollBot = childRect.bottom - parentRect.bottom;
            if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                // we're near the top of the list
                parent.scrollTop += scrollTop;
            } else {
                // we're near the bottom of the list
                parent.scrollTop += scrollBot;
            }
        }
    }

    function collapseMenu() {
        document.getElementById('RightInfoContainer').classList.add('collapsed')
        document.getElementById('collapse').style.display = "none";
        document.getElementById('expand').style.display = "block";
    }

    function expandMenu() {
        document.getElementById('RightInfoContainer').classList.remove("collapsed");
        document.getElementById('collapse').style.display = "block";
        document.getElementById('expand').style.display = "none";
    }

    return (
        <div>
            <button id="collapse" onClick={() => collapseMenu()}>&raquo;</button>
            <button id="expand" onClick={() => expandMenu()}>&laquo;</button>
            <div id="menuContent">
            <div className="section" id="room">
            <h3>Ruimte</h3>
                <div className="row"><span className="label">Breedte</span><SimpleFormItem type="range" value={width} setValue={setWidth} min={300} max={750} step={1}/><SimpleFormItem type="number" value={width}  min={300} max={750} step={1} setValue={setWidth}/></div>
                <div className="row"><span className="label">Hoogte</span><SimpleFormItem type="range" value={height} setValue={setHeight} min={200} max={400} step={1}/><SimpleFormItem type="number" value={height} min={200} max={400} setValue={setHeight} step={1}/></div>
                <div className="row"><span className="label">Diepte</span><SimpleFormItem type="range" value={depth} setValue={setDepth} min={300} max={1100} step={1}/><SimpleFormItem type="number" value={depth} min={300} max={1100} setValue={setDepth} step={1} /></div>


                <div className="row"><label>Ledverlichting: <input type="checkbox" checked={floorLedsOn} onChange={e => setFloorLedsOn(e.target.checked)}/></label></div>
                <div className="row floor">
                    <span className="label">Kleur vloer</span>
                    <label><span className="floor-light" data-color={floorColor}><span className="inner"></span></span><SimpleFormItem type="radio" name="floorcolor" value={FloorColors.Light} setValue={setFloorColor} onChange={e => setFloorColor(FloorColors.Light)} /></label>
                    <label><span className="floor-dark" data-color={floorColor}><span className="inner"></span></span><SimpleFormItem type="radio" name="floorcolor" value={FloorColors.Dark} setValue={setFloorColor} onChange={e => setFloorColor(FloorColors.Dark)} checked="checked"/></label>
                    <label><span className="floor-red" data-color={floorColor}><span className="inner"></span></span><SimpleFormItem type="radio" name="floorcolor" value={FloorColors.Red} setValue={setFloorColor} onChange={e => setFloorColor(FloorColors.Red)}/></label>
                </div>
                <button className="next" onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('video'))}>Naar scherm en projectie</button>
            </div>
            <div className="section" id="video">
                <h3>Scherm en projectie</h3>
                <div className="row"><span className="label">Breedte scherm</span><SimpleFormItem type="range" value={screenSize}
                                                                                         setValue={setScreenSize}
                                                                                         step={1}
                                                                                         min={minScreen}
                                                                                         max={Math.round(maxScreen)}/> <SimpleFormItem type="number" value={screenSize}  min={minScreen} max={maxScreen} step={1} setValue={setScreenSize}/>
                </div>
                <div className="row"><label>Type projectie</label> <SimpleFormItem type="select" value={screenID}
                                                                         setValue={setScreenID}>
                    <option value={ScreenConfig.epson_eh_tw9400.id}>Front projectie</option>
                    <option value={ScreenConfig.optoma_uhz65.id}>Ultra Short Throw</option>
                </SimpleFormItem></div>

                <div className="row"><label>Aspect ratio</label> <SimpleFormItem type="select" value={aspectRatio}
                                                                                 setValue={v => setAspectRatio(v)}>
                    {
                        aspectRatios.map((elm, i) => <option value={elm} key={i}>{elm}</option>)
                    }
                </SimpleFormItem></div>
                <div className="row"><span className="label">Afstand tot scherm</span><SimpleFormItem type="range" value={distance} setValue={setDistance} min={1} max={depth} step={1}/> <SimpleFormItem type="number" value={distance}  min={1} max={depth} step={1} setValue={setDistance}/></div>
                <button className="next" onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('audio'))}>Naar audio setup</button>
            </div>
            <div className="section" id="audio">
            <h3>Audio setup</h3>
                <div className="row"><label>Type</label>
                <SimpleFormItem type="select" value={surroundType} setValue={setSurroundType}>
                    <option value="5.1">5.1</option>
                    <option value="7.1">7.1</option>
                    <option value="5.1.2">5.1.2</option>
                    <option value="5.1.4">5.1.4</option>
                    <option value="7.1.2">7.1.2</option>
                    <option value="7.1.4">7.1.4</option>
                </SimpleFormItem>
                </div>
                <div className="row" data-disabled={numCeilingSpeakers !== 2}><label>Rear in ceiling
                <input type="checkbox" checked={rearInCeiling}
                                       onChange={e => setRearInCeiling(e.target.checked)}
                       disabled={numCeilingSpeakers !== 2}/></label></div>
                <button className="next" onClick={() => scrollParentToChild(document.getElementById('menu'), document.getElementById('seats'))}>Naar zitcomfort</button>
            </div>
            <div className="section" id="seats">
            <h3>Zitcomfort</h3>
                <div className="row"><span className="label">Type</span><SimpleFormItem type="select" value={seatID} setValue={setSeatID}>
                    <option value="cinemove">Bioscoopstoel</option>
                    <option value="roma">Bioscoopzetel</option>
                </SimpleFormItem></div>

                <div className="row"><span className="label">Kleur</span> {
                    models.map((elm, i) =>
                        <SimpleFormItem type="radio" name="seatcolor" data-seat={seatID} value={i} key={i} setValue={setModelID} onChange={e => setModelID(i)} />
                    )
                }</div>
                <div className="row"><span className="label">Aantal per rij</span><SimpleFormItem type="range" value={numSeats} setValue={setNumSeats} min={2} max={6} step={1}/> <SimpleFormItem type="number" value={numSeats}  min={2} max={6} step={1} setValue={setNumSeats}/></div>
                <div className="row"><span className="label">Aantal rijen</span><SimpleFormItem type="range" value={numRows} setValue={setNumRows} min={1} max={4} step={1}/> <SimpleFormItem type="number" value={numRows}  min={1} max={4} step={1} setValue={setNumRows}/></div>
                <div className="row-extended" data-rows={numRows}>
                <div className="row"><span className="label">Primaire rij</span><SimpleFormItem type="range" value={preferredRow} setValue={setPreferredRow} min={0} max={numRows-1} /></div>
                <div className="row"><span className="label">Extra breedte podium</span><SimpleFormItem type="select" value={aisleMode} setValue={setAisleMode}>
                    <option value={AisleMode.Left}>Links</option>
                    <option value={AisleMode.Right}>Rechts</option>
                    <option value={AisleMode.Both}>Beide kanten</option>
                </SimpleFormItem></div>
                    <div className="row"><SimpleFormItem type="range" value={aisleWidth} setValue={setAisleWidth} min={0} max={maxAisleWidth} step={1}/><SimpleFormItem type="number" value={aisleWidth}  min={0} max={4} step={maxAisleWidth} setValue={setAisleWidth}/></div>
                </div>
                <button className="next" onClick={() => document.getElementById('summaryForm').style.display = "block"}>Samenvatting</button>
            </div>
            </div>
        </div>
    );
}
