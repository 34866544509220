import {useSeatConfig} from "../../model/SeatConfigContext";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {GammaEncoding, LinearEncoding, MeshLambertMaterial, Plane, Vector3} from "three";
import ModelInstance from "./ModelInstance";
import {usePodiumGeometry} from "./geometry/usePodiumGeometry";
import {useScoreColor} from "./hooks/useScoreColor";
import {usePlanarDrag} from "./hooks/usePlanarDrag";
import {CameraMode, useInteractionContext} from "../../model/InteractionContext";
import {useRoomConfig} from "../../model/RoomConfigContext";
import useGLTF from "./hooks/useGLTF";
import {useTextures} from "./TextureContext";
import {useShadowGeometry} from "./geometry/useShadowGeometry";
import {useShadowMaterial} from "./materials/useShadowMaterial";

export default function Seats() {
    const {
        podiumWidth,
        podiumOffset,
        distance,
        setDistance,
        rowDepth,
        numSeats,
        rowHeight,
        size,
        numRows,
        gltf,
        score,
        recline,
        armrestWidth
    } = useSeatConfig();
    const {floorLedsOn} = useRoomConfig();
    const {cameraMode} = useInteractionContext();
    const model = useGLTF(gltf, MeshLambertMaterial);
    const {podiumLEDTexture} = useTextures();
    const podiumColor = 0x101010;
    const podiumGeom = usePodiumGeometry();
    const shadowGeometry = useShadowGeometry();
    const shadowMaterial = useShadowMaterial();

    const podiumMaterials = useMemo(() => {
        const mats = [];
        if (!podiumLEDTexture) return;
        for (let i = 1; i < numRows; ++i) {
            const tex = podiumLEDTexture.clone();
            tex.needsUpdate = true;
            tex.repeat.y = i;
            tex.outputEncoding = LinearEncoding;
            tex.encoding = GammaEncoding;
            mats.push(new MeshLambertMaterial({
                color: podiumColor,
                emissiveMap: tex,
                emissiveIntensity: floorLedsOn ? 1 : 0,
                emissive: 0x0a0a80
            }));
        }
        return mats;
        // don't want to recreate materials if floorLedsOn changes
        // eslint-disable-next-line
    }, [podiumLEDTexture, numRows]);

    useEffect(() => {
        podiumMaterials?.forEach(mat => mat.emissiveIntensity = floorLedsOn ? 1 : 0)
    }, [podiumMaterials, floorLedsOn])

    const positions = useMemo(() => {
        const p = [];
        const xo = (-(numSeats - 1) * size[0]) * .5;
        for (let z = 0; z < numRows; ++z) {
            for (let x = 0; x < numSeats; ++x) {
                p.push([
                    x * size[0] + xo,
                    z * rowHeight,
                    -z * rowDepth + recline * .125,
                ]);
            }
        }
        return p;
    }, [rowDepth, rowHeight, numSeats, numRows, size, recline]);

    const podia = useMemo(() => {
        const rows = [];
        for (let i = 1; i < numRows; ++i) {
            rows.push(i * rowDepth + positions[0][2]);
        }
        return rows;
    }, [numRows, rowDepth, positions]);

    const ref = useRef();
    const dragPlane = useMemo(() => new Plane(new Vector3(0, 1, 0), 0), []);
    const startDistance = useRef(0);

    const onStartDrag = useCallback(() => {
        startDistance.current = distance;
    }, [distance])
    const onDrag = useCallback(p => {
        if (cameraMode !== CameraMode.Seat)
            setDistance(startDistance.current - p.z);
    }, [setDistance, cameraMode]);
    const drag = usePlanarDrag(
        dragPlane,
        onDrag,
        onStartDrag
    );

    // pass in config id to trigger updates
    useScoreColor(ref?.current, score, model);

    return (
        <group position={[0, 0, -distance]} {...drag}>
            <group ref={ref}>
                {
                    positions.map((elm, i) =>
                        <group key={i} position={elm}>
                            <ModelInstance object={model?.scene}/>
                            <mesh scale={size} position={[0, -elm[1], 0]} geometry={shadowGeometry}
                                  material={shadowMaterial}/>
                        </group>
                    )
                }
            </group>
            {
                podia.map((elm, i) =>
                    <mesh scale={[podiumWidth, rowHeight * (i + 1), rowDepth]}
                          position={[podiumOffset, 0, -elm]} key={i}
                          geometry={podiumGeom} material={podiumMaterials[i]}/>
                )
            }
        </group>
    );
}
