import {useMemo} from "react";

export default function SimpleFormItem({ type, setValue = f => f, children, ...props })
{
    const apply = useMemo(() => {
        // need to do some parsing to prevent setting string values -_-
        if (type === "range") {
            return val => setValue(parseFloat(val));
        }
        else
            return val => setValue(val);
    }, [type, setValue]);

    if (type === "select") {
        return <select {...props} type={type} onChange={e => apply(e.target.value)}>{children}</select>
    }

    return <input {...props} type={type} onChange={e => apply(e.target.value)}>{children}</input>
}
