import {useEffect} from "react";

const colors = [
    0x000000,
    0xffff00,
    0xff8000,
    0xff0000
];

export const useScoreColor = (target, score, modelRef) => {
    useEffect(() => {
        if (!target) return;
        target.traverse(obj => {
            if (obj.isMesh) {
                const mat = obj.material;

                if (mat && mat.emissive) {
                    mat.emissive.set(colors[score]);
                    mat.emissiveIntensity = 0.2;
                }
            }
        });
    }, [score, target, modelRef]);
}