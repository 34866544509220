import {useMemo} from "react";
import {useScreenConfig} from "../ScreenConfigContext";
import {ScreenType} from "../../config/ScreenConfig";

export const useCenterSpeaker = (config) => {
    const {type, screenFloorDistance} = useScreenConfig();
    return useMemo(() => {
        const d = config.size[2] * .5;
        // cupboard height
        let y, z = -d;
        if (type === ScreenType.UltraShortThrow) {
            y = 12;
            z -= 20;
        }
        else {
            y = screenFloorDistance - config.size[1] - 10;
        }
        return {
            position: [0, y, z],
            rotation: Math.PI
        }
    }, [config, type, screenFloorDistance]);
}
