import {Matrix4, PlaneBufferGeometry} from "three";

let geom;

export function useWallGeometry()
{
    if (!geom) {
        geom = new PlaneBufferGeometry(1, 1);
        geom.applyMatrix4(new Matrix4().makeTranslation(0, 0.5, 0));
        geom.setAttribute("uv2", geom.getAttribute("uv"));
    }
    return geom;
}