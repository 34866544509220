import {useConstrainedState} from "../../hooks/useConstrainedState";
import {useCallback} from "react";
import {MathUtils} from "three";

// A hook that manages the screen state within the allowed screen range for the current projector.
export function useScreenSize(config, initSize)
{
    const constraint = useCallback(val => {
        if (config.hasOwnProperty("screenRange")) {
            val = MathUtils.clamp(val, config.screenRange[0], config.screenRange[1]);
        }
        return val;
    }, [ config ]);
    return useConstrainedState(initSize, constraint)
}