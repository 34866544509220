import {DoubleSide, MeshLambertMaterial} from "three";

export default class CeilingSpeakerMaterial extends MeshLambertMaterial
{
    constructor(parameters)
    {
        super(parameters);
        this.side = DoubleSide;
        this.polygonOffset = true;
        this.polygonOffsetUnits = -100;
    }
}