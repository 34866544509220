import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {getAngleScore, getMaximumOffset} from "../utils/speakerUtils";
import {useScreenConfig} from "../ScreenConfigContext";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";
import {getAngle} from "../utils/trig";
import {useSeatConfig} from "../SeatConfigContext";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useFrontSpeakerValidator = (config, positions) => {
    const {width} = useRoomConfig();
    const {screenSize} = useScreenConfig();
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        const score = createScore();
        // use the diagonal as the minimum offset to walls
        const maxW = getMaximumOffset(config.size) * .5;
        const maxX = width * .5 - maxW;
        const minX = screenSize * .5 + maxW;
        // they're always symmetric, so just use the first speaker's x position
        const x = positions[0].position[0];

        if (x < minX)
            updateScore(score, 3, ErrorCodes.FrontSpeakersBlockScreen);
        else if (x > maxX)
            updateScore(score, 3, ErrorCodes.FrontSpeakersTooCloseToWalls);

        const angle = getAngle(x, -primaryEarPosition[2]);

        updateScore(
            score,
            getAngleScore(angle, 26, 22, 30, 4),
            ErrorCodes.FrontSpeakersWrongAngle
        );

        return score;
    }, [primaryEarPosition, width, config, screenSize, positions]);
}