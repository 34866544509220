import {MeshBasicMaterial, MultiplyBlending} from "three";
import {useMemo} from "react";
import {useTextures} from "../TextureContext";

export function useShadowMaterial()
{
    const { shadowTexture } = useTextures();
    return useMemo(() => new MeshBasicMaterial({
        map: shadowTexture,
        polygonOffset: true,
        polygonOffsetUnits: -5,
        transparent: true,
        blending: MultiplyBlending,
        depthWrite: false,
    }), [shadowTexture]);
}