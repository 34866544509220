// trigonometry functions. Google SOHCAHTOA for a high-school trig refresher :)
// All of these accept or return angles in degrees!

/**
 * Considering a right triangle, it calculates the length of the opposite side.
 * @param adjacent The length of the adjacent side.
 * @param angle The angle.
 */
export function getOppositeLength(adjacent, angle)
{
    // tan(angle) = opposite / adjacent
    // iff
    // opposite = tan(angle) * adjacent
    return Math.tan(angle * Math.PI / 180) * adjacent;
}

/**
 * Considering a right triangle, it calculates the length of the adjacent side.
 * @param opposite The length of the opposite side.
 * @param angle The angle.
 */
export function getAdjacentLength(opposite, angle)
{
    // tan(angle) = opposite / adjacent
    // iff
    // adjacent = opposite / tan(angle)
    return opposite / Math.tan(angle * Math.PI / 180);
}

/**
 * Gets the angle of a corner of a right-triangle
 * @param opposite The right side opposite of the angle
 * @param adjacent The right side adjacent to the angle
 */
export function getAngle(opposite, adjacent)
{
    return Math.atan2(opposite, adjacent) / Math.PI * 180;
}