import {useEffect, useRef, useState} from "react";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {useInteractionContext} from "../../../model/InteractionContext";

function replaceMaterial(obj, material)
{
    obj.traverse(obj => {
        if (obj.isMesh) {
            const old = obj.material;
            const mat = new material();
            obj.material = mat;
            if (old) {
                mat.color = old.color;
                mat.map = old.map;
            }
            obj.material = mat;
        }
    });
}

export default function useGLTF(file, material)
{
    const loader = useRef(null);
    const [asset, setAsset] = useState(null);
    const {startLoading, endLoading} = useInteractionContext();

    useEffect(() => {
        if (loader.current)
            loader.current.active = false;

        if (!file) return;

        startLoading();
        const gltf = new GLTFLoader();
        loader.current = gltf;
        // hack to intercept loads (shouldn't occur with loader)
        gltf.active = true;
        gltf.load(file, asset => {
            if(!gltf.active)
                return;

            if (material)
                replaceMaterial(asset.scene, material);

            asset.scene.scale.set(100, 100, 100);
            setAsset(asset);

            endLoading();
        }, null, e => {
            console.log("Error loading model " + file);
            console.log(e);
            endLoading();
        });
    // eslint-disable-next-line
    }, [file, material, endLoading, startLoading]);

    return asset;
}