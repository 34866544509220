/**
 * This calculates the min, max and optional distance a projector should be at based on its config (throw-ratio) and the
 * target screen size.
 * @param config The projector config.
 * @param screenSize The size of the screen.
 * @returns An array containing [min, max, optimal] distances.
 */
export function getProjectorDistances(config, screenSize)
{
    const minDistance = screenSize * config.throwRatioMin;
    const maxDistance = screenSize * config.throwRatioMax;
    const optimal = (minDistance + maxDistance) * .5;
    return [minDistance, maxDistance, optimal];
}