/* eslint-disable */
import {EventDispatcher, PerspectiveCamera, Quaternion, Spherical, Vector2, Vector3,} from 'three'

// This set of controls performs look-around actions
export class LookControls extends EventDispatcher
{
    constructor(object, domElement)
    {
        super()

        // Set to false to disable this control
        this.enabled = true
        // "target" sets the location of focus, where the object orbits around
        this.target = new Vector3()
        // How far you can orbit vertically, upper and lower limits.
        // Range is 0 to Math.PI radians.
        this.minPolarAngle = 0 // radians
        this.maxPolarAngle = Math.PI // radians
        // How far you can orbit horizontally, upper and lower limits.
        // If set, the interval [ min, max ] must be a sub-interval of [ - 2 PI, 2 PI ], with ( max - min < 2 PI )
        this.minAzimuthAngle = -Infinity // radians
        this.maxAzimuthAngle = Infinity // radians
        // Set to true to enable damping (inertia)
        // If damping is enabled, you must call controls.update() in your animation loop
        this.enableDamping = false
        this.dampingFactor = 0.05
        this.rotateSpeed = 1.0

        this.object = object
        this.domElement = domElement
        // for reset
        this.target0 = this.target.clone()
        this.position0 = this.object.position.clone()
        this.zoom0 = this.object instanceof PerspectiveCamera ? this.object.zoom : 1

        //
        // public methods
        //

        this.getPolarAngle = () => spherical.phi

        this.getAzimuthalAngle = () => spherical.theta

        this.saveState = () => {
            scope.target0.copy(scope.target)
            scope.position0.copy(scope.object.position)
            scope.zoom0 = scope.object instanceof PerspectiveCamera ? scope.object.zoom : 1
        }

        this.reset = () => {
            scope.target.copy(scope.target0)
            scope.object.position.copy(scope.position0)
            if (scope.object instanceof PerspectiveCamera) {
                scope.object.zoom = scope.zoom0
                scope.object.updateProjectionMatrix()
            }

            scope.dispatchEvent(changeEvent)

            scope.update()

            state = STATE.NONE
        }

// this method is exposed, but perhaps it would be better if we can make it private...
        this.update = (() => {
            const offset = new Vector3()

            // so camera.up is the orbit axis
            const quat = new Quaternion().setFromUnitVectors(object.up, new Vector3(0, 1, 0))
            const quatInverse = quat.clone().invert()

            const lastPosition = new Vector3()
            const lastQuaternion = new Quaternion()

            const twoPI = 2 * Math.PI

            return function update() {
                offset.copy(scope.object.position).sub(scope.target);

                // rotate offset to "y-axis-is-up" space
                offset.applyQuaternion(quat)

                // angle from z-axis around y-axis
                spherical.setFromVector3(offset)

                if (scope.enableDamping) {
                    spherical.theta += sphericalDelta.theta * scope.dampingFactor
                    spherical.phi += sphericalDelta.phi * scope.dampingFactor
                } else {
                    spherical.theta += sphericalDelta.theta
                    spherical.phi += sphericalDelta.phi
                }

// restrict theta to be between desired limits

                let min = scope.minAzimuthAngle
                let max = scope.maxAzimuthAngle

                if (isFinite(min) && isFinite(max)) {
                    if (min < -Math.PI) min += twoPI
                    else if (min > Math.PI) min -= twoPI

                    if (max < -Math.PI) max += twoPI
                    else if (max > Math.PI) max -= twoPI

                    if (min <= max) {
                        spherical.theta = Math.max(min, Math.min(max, spherical.theta))
                    } else {
                        spherical.theta =
                            spherical.theta > (min + max) / 2 ? Math.max(min, spherical.theta) : Math.min(max, spherical.theta)
                    }
                }

// restrict phi to be between desired limits
                spherical.phi = Math.max(scope.minPolarAngle, Math.min(scope.maxPolarAngle, spherical.phi))
                spherical.makeSafe()
                spherical.radius *= scale

// move target to panned location
                offset.setFromSpherical(spherical)

// rotate offset back to "camera-up-vector-is-up" space
                offset.applyQuaternion(quatInverse)

                scope.target.copy(scope.object.position).sub(offset);

                scope.object.lookAt(scope.target)

                if (scope.enableDamping === true) {
                    sphericalDelta.theta *= 1 - scope.dampingFactor
                    sphericalDelta.phi *= 1 - scope.dampingFactor
                } else {
                    sphericalDelta.set(0, 0, 0)
                }

                scale = 1

// update condition is:
// min(camera displacement, camera rotation in radians)^2 > EPS
// using small-angle approximation cos(x/2) = 1 - x^2 / 8

                if (8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS) {
                    scope.dispatchEvent(changeEvent)

                    lastPosition.copy(scope.object.position)
                    lastQuaternion.copy(scope.object.quaternion)

                    return true
                }

                return false
            }
        })()

// https://github.com/mrdoob/three.js/issues/20575
        this.connect = (domElement) => {
            if (domElement === document) {
                console.error(
                    'THREE.OrbitControls: "document" should not be used as the target "domElement". Please use "renderer.domElement" instead.',
                )
            }
            scope.domElement = domElement
            scope.domElement.addEventListener('contextmenu', onContextMenu)
            scope.domElement.addEventListener('pointerdown', onPointerDown)
            scope.domElement.addEventListener('touchstart', onTouchStart)
            scope.domElement.addEventListener('touchend', onTouchEnd)
            scope.domElement.addEventListener('touchmove', onTouchMove)
        }

        this.dispose = () => {
            scope.domElement?.removeEventListener('contextmenu', onContextMenu)
            scope.domElement?.removeEventListener('pointerdown', onPointerDown)
            scope.domElement?.removeEventListener('touchstart', onTouchStart)
            scope.domElement?.removeEventListener('touchend', onTouchEnd)
            scope.domElement?.removeEventListener('touchmove', onTouchMove)
            scope.domElement?.ownerDocument.removeEventListener('pointermove', onPointerMove)
            scope.domElement?.ownerDocument.removeEventListener('pointerup', onPointerUp)
            //scope.dispatchEvent( { type: 'dispose' } ); // should this be added here?
        }

//
// internals
//

        const scope = this

        const changeEvent = {type: 'change'}
        const startEvent = {type: 'start'}
        const endEvent = {type: 'end'}

        const STATE = {
            NONE: -1,
            ROTATE: 0,
        }

        let state = STATE.NONE

        const EPS = 0.000001

// current position in spherical coordinates
        const spherical = new Spherical()
        const sphericalDelta = new Spherical()

        let scale = 1

        const rotateStart = new Vector2()
        const rotateEnd = new Vector2()
        const rotateDelta = new Vector2()

        function rotateLeft(angle)
        {
            sphericalDelta.theta -= angle
        }

        function rotateUp(angle)
        {
            sphericalDelta.phi -= angle
        }

//
// event callbacks - update the object state
//

        function handleMouseDownRotate(event)
        {
            rotateStart.set(event.clientX, event.clientY)
        }

        function handleMouseMoveRotate(event)
        {
            rotateEnd.set(event.clientX, event.clientY)
            rotateDelta.subVectors(rotateEnd, rotateStart).multiplyScalar(scope.rotateSpeed)

            const element = scope.domElement

            if (element) {
                rotateLeft((2 * Math.PI * rotateDelta.x) / element.clientHeight) // yes, height
                rotateUp((2 * Math.PI * rotateDelta.y) / element.clientHeight)
            }
            rotateStart.copy(rotateEnd)
            scope.update()
        }

        function handleMouseUp(/*event*/)
        {
            // no-op
        }

        function handleTouchStartRotate(event)
        {
            if (event.touches.length == 1) {
                rotateStart.set(event.touches[0].pageX, event.touches[0].pageY)
            } else {
                const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
                const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

                rotateStart.set(x, y)
            }
        }

        function handleTouchMoveRotate(event)
        {
            if (event.touches.length == 1) {
                rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY)
            } else {
                const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX)
                const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY)

                rotateEnd.set(x, y)
            }

            rotateDelta.subVectors(rotateEnd, rotateStart).multiplyScalar(scope.rotateSpeed)

            const element = scope.domElement

            if (element) {
                rotateLeft((2 * Math.PI * rotateDelta.x) / element.clientHeight) // yes, height
                rotateUp((2 * Math.PI * rotateDelta.y) / element.clientHeight)
            }
            rotateStart.copy(rotateEnd)
        }

        function handleTouchEnd(/*event*/)
        {
            // no-op
        }

//
// event handlers - FSM: listen for events and reset state
//

        function onPointerDown(event)
        {
            if (scope.enabled === false) return

            switch (event.pointerType) {
                case 'mouse':
                case 'pen':
                    onMouseDown(event)
                    break

                // TODO touch
            }
        }

        function onPointerMove(event)
        {
            if (scope.enabled === false) return

            switch (event.pointerType) {
                case 'mouse':
                case 'pen':
                    onMouseMove(event)
                    break

                // TODO touch
            }
        }

        function onPointerUp(event)
        {
            switch (event.pointerType) {
                case 'mouse':
                case 'pen':
                    onMouseUp()
                    break

                // TODO touch
            }
        }

        function onMouseDown(event)
        {
            // Prevent the browser from scrolling.
            event.preventDefault()

            // Manually set the focus since calling preventDefault above
            // prevents the browser from setting it automatically.

            scope.domElement?.focus ? scope.domElement.focus() : window.focus()

            handleMouseDownRotate(event)
            state = STATE.ROTATE

            scope.domElement?.ownerDocument.addEventListener('pointermove', onPointerMove)
            scope.domElement?.ownerDocument.addEventListener('pointerup', onPointerUp)
            scope.dispatchEvent(startEvent)
        }

        function onMouseMove(event)
        {
            if (scope.enabled === false) return

            event.preventDefault()

            if (state === STATE.ROTATE) {
                if (scope.enableRotate === false) return
                handleMouseMoveRotate(event)
            }
        }

        function onMouseUp()
        {
            scope.domElement?.ownerDocument.removeEventListener('pointermove', onPointerMove)
            scope.domElement?.ownerDocument.removeEventListener('pointerup', onPointerUp)

            if (scope.enabled === false) return
            handleMouseUp()
            scope.dispatchEvent(endEvent)
            state = STATE.NONE
        }

        function onTouchStart(event)
        {
            if (scope.enabled === false) return

            event.preventDefault() // prevent scrolling

            if (event.touches.length === 1) {
                if (scope.enableRotate === false)
                    return
                handleTouchStartRotate(event)
                state = STATE.TOUCH_ROTATE
                scope.dispatchEvent(startEvent)
            } else {
                state = STATE.NONE
            }
        }

        function onTouchMove(event)
        {
            if (scope.enabled === false) return

            event.preventDefault() // prevent scrolling

            if (state === STATE.TOUCH_ROTATE) {
                if (scope.enableRotate === false) return
                handleTouchMoveRotate(event)
                scope.update()
            }
        }

        function onTouchEnd()
        {
            if (scope.enabled === false) return

            handleTouchEnd()
            scope.dispatchEvent(endEvent)
            state = STATE.NONE
        }

        function onContextMenu(event)
        {
            if (scope.enabled === false) return
            event.preventDefault()
        }

// connect events
        if (domElement !== undefined) this.connect(domElement)
// force an update at start
        this.update()
    }
}