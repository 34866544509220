import './App.scss';
import View3D from "./components/View3D/View3D";
import AppData from "./model/AppData";
import Menu from "./components/Menu/Menu";
import TopMenu from "./components/Menu/TopMenu";
import ErrorMessages from "./components/Menu/ErrorMessages";
import Loader from "./components/Loader";

function App()
{


    const queryString = window.location.search;
    const UrlParams = new URLSearchParams(queryString);
    if (UrlParams.has('screenshot')) {
        return (
            <div className="App">
                <AppData>
                    <div className="ViewCanvas">
                        <View3D/>
                    </div>
                    <Loader />
                    { /* Add UI */}
                </AppData>
            </div>
        );
    }
    return (
        <div className="App">
            <AppData>
                <div className="TopMenu">
                    <TopMenu/>
                </div>
                <div className="ViewCanvas">
                <View3D/>
                </div>
                <div className="RightInfo" id="RightInfoContainer">
                <div className="Menu" id="menu">
                    <Menu/>
                </div>
                <div className="Errors">
                    <ErrorMessages />
                </div>
                </div>
                <Loader />
                { /* Add UI */}
            </AppData>
        </div>
    );
}

export default App;
