import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useSeatConfig} from "../SeatConfigContext";
import {getMaximumOffset} from "../utils/speakerUtils";
import {getOppositeLength} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useRearSpeakers = (numSpeakers, rearInCeiling, wallConfig, ceilingConfig) => {
    const {width, height, depth} = useRoomConfig();
    const {primaryEarPosition, numRows, rowHeight} = useSeatConfig();

    return useMemo(() => {
        // no back speakers for 5.1
        if (numSpeakers === 5) return [];
        // use the diagonal as the minimum offset to walls
        const size = rearInCeiling ? wallConfig.size : ceilingConfig.size;
        const maxW = getMaximumOffset(size) * 1.5;
        const maxX = width * .5 - maxW;
        const z = depth - maxW;
        const distToSeat = z - -primaryEarPosition[2];
        let x = getOppositeLength(distToSeat, 180 - 142.5);
        x = Math.min(maxX, x);
        // center the speakers at 120cm
        const y = rearInCeiling ? height : 120 - wallConfig.size[1] * .5 + (numRows - 1) * rowHeight;
        const angle = -Math.atan2(x, distToSeat);

        return [
            {
                position: [x, y, -z],
                rotation: angle
            },
            {
                position: [-x, y, -z],
                rotation: -angle
            }
        ];
    }, [width, height, depth, primaryEarPosition, numSpeakers, wallConfig, ceilingConfig, rearInCeiling, numRows, rowHeight]);
}