import {OrbitControls} from "@react-three/drei";
import {CameraMode, useInteractionContext} from "../../../model/InteractionContext";
import {useRoomConfig} from "../../../model/RoomConfigContext";
import {useEffect, useMemo} from "react";
import {useSeatConfig} from "../../../model/SeatConfigContext";
import {useThree} from "@react-three/fiber";
import {LookAroundControls} from "./LookAroundControls";

export const CameraControls = () => {
    const {isDragging, cameraMode, cameraX, cameraY} = useInteractionContext();
    const {camera} = useThree();
    const {width, depth} = useRoomConfig();
    const orbitEnabled = useMemo(() => !isDragging, [isDragging]);
    // forces render state to be applied after camera reset
    const {primarySeatDistance, primaryEarPosition} = useSeatConfig();

    // handle orbit seperately, since it shouldn't trigger on primary seat distance changes (would reset on drag)
    useEffect(() => {
        if (cameraMode === CameraMode.Orbit) {
            // camera.fov = 75;
            camera.position.set(-400, cameraX, cameraY);
        }
    }, [camera, cameraMode]);

    useEffect(() => {
        switch (cameraMode) {
            case CameraMode.TopDown:
                // camera.fov = 75;
                camera.position.set(0, 200 + width * .90, -depth * .5);
                camera.rotation.set(-Math.PI * .5, 0, -Math.PI * .5);
                break;
            case CameraMode.Seat:
                // camera.fov = 160;
                camera.position.fromArray(primaryEarPosition);
                camera.rotation.set(0, Math.PI, 0);
                break;
            default:
                break;
        }
    }, [camera, cameraMode, primarySeatDistance, primaryEarPosition, width, depth]);

    if (cameraMode === CameraMode.Orbit)
        return (
            <OrbitControls enabled={orbitEnabled} enablePan={false}
                           target={[0, 75, -depth * .5]} minDistance={100} maxDistance={500}
                           maxPolarAngle={Math.PI * .5}/>
        );

    if (cameraMode === CameraMode.Seat)
        return (
            <LookAroundControls enabled={cameraMode === CameraMode.Seat} minAzimuthAngle={Math.PI * .5}
                                maxAzimuthAngle={-Math.PI * .5}/>
        );

    // top-down
    return <></>;
}
