import {Matrix4, PlaneBufferGeometry, Vector3} from "three";

const mat = new Matrix4();
mat.makeTranslation(0, -0.2, 0);
mat.premultiply(new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), -Math.PI * .5));

let geom;

export function useShadowGeometry()
{
    if (!geom) {
        geom = new PlaneBufferGeometry(3, 1.5);
        geom.applyMatrix4(mat);
    }

    return geom;
}