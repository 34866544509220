import {useScreenConfig} from "../../model/ScreenConfigContext";
import ModelInstance from "./ModelInstance";
import {ScreenType} from "../../config/ScreenConfig";
import {useMemo, useRef} from "react";
import {useScoreColor} from "./hooks/useScoreColor";
import {MeshBasicMaterial, MeshLambertMaterial} from "three";
import {useAspectRatio} from "../../hooks/useAspectRatio";
import useGLTF from "./hooks/useGLTF";
import {useRoomConfig} from "../../model/RoomConfigContext";
import {useTextures} from "./TextureContext";
import {useTextureAspectStretch} from "./hooks/useTextureAspectStretch";

export default function Screen() {
    const {
        type,
        gltf,
        projectorPosition,
        screenSize,
        aspectRatio,
        projectorScore,
        screenFloorDistance,
        screenScore
    } = useScreenConfig();
    const {screenTextureDark, screenTextureLight} = useTextures();
    const model = useGLTF(gltf, MeshLambertMaterial);
    const projector = useRef();
    const screen = useRef();
    const borderSize = useMemo(() => type === ScreenType.UltraShortThrow ? 2 : 5, [type]);
    const aspect = useAspectRatio(aspectRatio);
    const outerSize = useMemo(() => [screenSize, screenSize / aspect, 1], [screenSize, aspect])
    const innerSize = useMemo(() => [outerSize[0] - borderSize * 2, outerSize[1] - borderSize * 2, 1], [outerSize, borderSize])
    const {mainLightOn} = useRoomConfig();
    const screenTexture = useMemo(() => mainLightOn ? screenTextureLight : screenTextureDark, [mainLightOn, screenTextureLight, screenTextureDark]);
    const screenMaterial = useMemo(() =>
        new MeshBasicMaterial({
            color: 0xffffff, polygonOffset: true, polygonOffsetUnits: -5, map: screenTexture, toneMapped: false
        }), [screenTexture]);

    useTextureAspectStretch(screenTexture, aspect);

    useScoreColor(projector?.current, projectorScore, model);
    useScoreColor(screen?.current, screenScore);

    return (
        <group>
            <group ref={screen} position={[0, screenFloorDistance + outerSize[1] * .5, -1]} rotation={[0, Math.PI, 0]}>
                <mesh scale={outerSize}>
                    <planeBufferGeometry/>
                    <meshLambertMaterial color={0x050505} attach="material"/>
                </mesh>
                <mesh scale={innerSize} material={screenMaterial}>
                    <planeBufferGeometry/>
                </mesh>
            </group>
            <ModelInstance ref={projector} visible={type !== ScreenType.FlatPanel} position={projectorPosition.position}
                           rotation={[0, projectorPosition.rotation, 0]}
                           object={model?.scene}/>
        </group>
    );
}