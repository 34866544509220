import {useMemo} from "react";
import {useScreenLimits} from "../../hooks/useScreenLimits";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";

export const useScreenValidator = (aspectRatio, screenRange, screenFloorDistance, screenSize) =>
{
    const [min, max] = useScreenLimits(aspectRatio, screenRange, screenFloorDistance);

    return useMemo(() => {
        const score = createScore();

        if (screenSize < min)
            updateScore(score, 3, ErrorCodes.ScreenTooSmall);
        else if (screenSize > max)
            updateScore(score, 3, ErrorCodes.ScreenTooLarge);

        return score;
    }, [min, max, screenSize]);
}