import ModelInstance from "./ModelInstance";
import {useScoreColor} from "./hooks/useScoreColor";
import {useRef} from "react";
import {SpeakerCone} from "./SpeakerCone";
import {useSeatConfig} from "../../model/SeatConfigContext";
import {useLookAt} from "./hooks/useLookAt";
import {useAudioConfig} from "../../model/AudioConfigContext";

export default function Speaker({model, config, score = 0, ...props}) {
    const ref = useRef();
    useScoreColor(ref?.current, score, model);
    const {primaryEarPosition} = useSeatConfig();
    const rotation = useLookAt(props.position, primaryEarPosition);
    const {showCones} = useAudioConfig();

    return (
        <group {...props}>
            <ModelInstance object={model?.scene} ref={ref}/>
            <group visible={showCones} position={config.speakerOffset} rotation={config.down? rotation: [0, 0, 0]}>
                <SpeakerCone angle={config.innerAngle} opacity={0.5}/>
                <SpeakerCone angle={config.outerAngle} opacity={0.25}/>
            </group>
        </group>
    );
}