import {useEffect} from "react";
import {GammaEncoding, LinearEncoding} from "three";

export function useTextureAspectStretch(texture, aspect)
{
    useEffect(() => {
        if (!texture) return;
        const srcAspect = texture.image.naturalWidth / texture.image.naturalHeight;
        texture.repeat.y = srcAspect / aspect;
        texture.offset.y = 0.5 - srcAspect / aspect * .5;
        texture.outputEncoding = LinearEncoding;
        texture.encoding = GammaEncoding;
    }, [texture, aspect])
}