import {useMemo} from "react";
import {ScreenType} from "../../config/ScreenConfig";

export const useSubwoofer = (config, screenType, frontPositions) => {
    return useMemo(() => {
        const d = config.size[2] * .5;
        let x = frontPositions[0].position[0] - 70;

        // do not intersect with cupboard
        if (screenType === ScreenType.UltraShortThrow) {
            x = Math.max(x, 90);
        }

        return {
            position: [ x, 0, -d - 5],
            rotation: Math.PI
        }
    }, [config, frontPositions, screenType]);
}