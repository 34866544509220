import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useCeilingSpeakers} from "./rules/useCeilingSpeakers";
import {AudioConfig} from "../config/AudioConfig";
import {useFrontSpeakers} from "./rules/useFrontSpeakers";
import {useFrontSpeakerValidator} from "./rules/useFrontSpeakerValidator";
import {useCeilingSpeakerValidator} from "./rules/useCeilingSpeakerValidator";
import {useSideSpeakers} from "./rules/useSideSpeakers";
import {useSideSpeakerValidator} from "./rules/useSideSpeakerValidator";
import {useRearSpeakers} from "./rules/useRearSpeakers";
import {useRearSpeakerValidator} from "./rules/useRearSpeakerValidator";
import {useSubwoofer} from "./rules/useSubwoofer";
import {useCenterSpeaker} from "./rules/useCenterSpeaker";
import {useScreenConfig} from "./ScreenConfigContext";

export const AudioConfigContext = createContext(null);

export default function AudioConfigProvider({children}) {
    // Allows changing the speaker brands from the menu (currently only 1 speaker config exists):
    const [config, setConfig] = useState(AudioConfig.klipsch);
    const setConfigID = useCallback(id => setConfig(AudioConfig[id]), []);
    // provide values 5.1, 7.1, 5.1.2, 5.1.4, 7.1.2 or 7.1.4.
    const [surroundType, setSurroundType] = useState("7.1.4");
    // whether or not the rear speakers should be in the ceiling
    const [rearInCeiling, setRearInCeiling] = useState(false);
    // whether or not the audio range cones should be displayed
    const [showCones, setShowCones] = useState(false);

    // splits the surroundType string into the number of speakers per type
    const [numRegularSpeakers, hasWoofer, numCeilingSpeakers] = useMemo(() => {
        const split = surroundType.split(".")
        // always has woofer
        return [parseInt(split[0]), true, split.length > 2 ? parseInt(split[2]) : 0];
    }, [surroundType]);

    // rear in ceiling can only be true for N.1.2 systems, so force it to false if it changes to N.1.4
    useEffect(() => {
        if (numCeilingSpeakers !== 2)
            setRearInCeiling(false);
    }, [numCeilingSpeakers]);

    const { type: screenType } = useScreenConfig();

    // Atmos-based rules
    const frontPositions = useFrontSpeakers(config.front);
    const ceilingPositions = useCeilingSpeakers(numCeilingSpeakers, config.ceiling, frontPositions);
    const sidePositions = useSideSpeakers(numRegularSpeakers, config.surround);
    const rearPositions = useRearSpeakers(numRegularSpeakers, rearInCeiling, config.surround, config.ceiling);
    const centerPosition = useCenterSpeaker(config.center);
    const subwooferPosition = useSubwoofer(config.subwoofer, screenType, frontPositions);

    const { score: frontSpeakersScore, errorCodes: frontSpeakersErrorCodes} = useFrontSpeakerValidator(config.front, frontPositions);
    const { score: ceilingSpeakersScore, errorCodes: ceilingSpeakersErrorCodes} = useCeilingSpeakerValidator(config.ceiling, ceilingPositions);
    const { score: sideSpeakersScore, errorCodes: sideSpeakersErrorCodes} = useSideSpeakerValidator(numRegularSpeakers, config.surround, sidePositions);
    const { score: rearSpeakersScore, errorCodes: rearSpeakersErrorCodes} = useRearSpeakerValidator(numRegularSpeakers, rearInCeiling, config.surround, config.ceiling, rearPositions);

    return (
        <AudioConfigContext.Provider value={{
            surroundType, setSurroundType,
            numRegularSpeakers, hasWoofer, numCeilingSpeakers,
            rearInCeiling, setRearInCeiling,
            ceilingPositions, frontPositions, sidePositions, rearPositions,
            ...config, setConfigID,
            ceilingSpeakersScore, ceilingSpeakersErrorCodes,
            frontSpeakersScore, frontSpeakersErrorCodes,
            sideSpeakersScore, sideSpeakersErrorCodes,
            rearSpeakersScore, rearSpeakersErrorCodes,
            subwooferPosition, centerPosition,
            showCones, setShowCones
        }}>
            {children}
        </AudioConfigContext.Provider>
    )
}

export const useAudioConfig = () => useContext(AudioConfigContext);