export const ScreenType = {
    // flat panels are currently unsupported
    FlatPanel: 0,
    Projector: 1,
    UltraShortThrow: 2
}

export const ScreenConfig = {
    epson_eh_tw9400: {
        id: "epson_eh_tw9400",
        name: "Epson EH-TW9400",
        type: ScreenType.Projector,
        throwRatioMin: 1.35,
        throwRatioMax: 2.84,
        gltf: "assets/models/Beamer_Epson001/Beamer_EpsonETHW9400W.gltf",
        // dimensions of the projector in cm
        size: [25.0, 45.0, 19.3],
        aspectRatios: [ "16:9", "21:9" ]
    },
    optoma_uhz65: {
        id: "optoma_uhz65",
        name: "Optoma UHZ65",
        type: ScreenType.UltraShortThrow,
        // fixed throw ratio for UST projector, so min = max
        throwRatioMin: 0.254,
        throwRatioMax: 0.254,
        gltf: "assets/models/Beamer_Optoma001/Beamer_OptomaUHZ65UST_001.gltf",
        size: [49.8, 15.2, 33.0],
        aspectRatios: [ "16:9" ],
        // limits of the screen sizes (if absent, there are no limits) [min, max]
        screenRange: [ 220, 270 ],
        // required for short throw, this is the vertical offset to calculate screen height.
        offset: 1.24,
        // required for short throw, this is the projector's mirror height to calculate screen height.
        mirrorHeight: 8.29
    },
};
