import {ConeBufferGeometry, Matrix4, Vector3} from "three";

let geom;
const matrix = new Matrix4();
matrix.makeTranslation(0, -0.5, 0);
matrix.premultiply(new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), -Math.PI * .5));

export function useConeGeometry()
{
    if (!geom) {
        geom = new ConeBufferGeometry(1, 1, 16, 1, false);
        geom.applyMatrix4(matrix);
    }

    return geom;
}