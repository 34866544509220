import {useMemo} from "react";
import {ScreenType} from "../../config/ScreenConfig";
import {useAspectRatio} from "../../hooks/useAspectRatio";

export default function useScreenFloorDistance(config, screenSize, aspectRatio, projectorPosition)
{
    const aspect = useAspectRatio(aspectRatio);

    return useMemo(() => {
        if (config.type !== ScreenType.UltraShortThrow)
            return 50;

        const screenHeight = screenSize / aspect;
        return projectorPosition.position[1] + screenHeight * (config.offset - 1) + config.mirrorHeight;
    }, [config, screenSize, aspect, projectorPosition]);
}
