export const SeatConfig = {
    cinemove: {
        id: "cinemove",
        models: [
            {
                name: "black",
                gltf: "assets/models/Seat_CineMove001/black/Seat_CineMove001.gltf"
            },
            {
                name: "red",
                gltf: "assets/models/Seat_CineMove001/red/Seat_CineMove001.gltf"
            }
        ],
        // the distance between two rows (= seatDepth + legRoom)
        rowDepth: 100,
        // the height of a row
        rowHeight: 40,
        // the distance between the anchor point and the backside of the chair, to allow for distance between the back wall
        recline: 30,
        // the physical dimensions of the chair in cm [w, h, d], WITHOUT 1 armrest
        size: [ 58, 108.5, 76 ],
        // the width of one armrest (to fill out a row)
        armrestWidth: 10.2
    },
    roma: {
        id: "roma",
        models: [
            {
                name: "black",
                gltf: "assets/models/Seat_RomaElite001/Seat_Roma001.gltf"
            }
        ],
        rowDepth: 199,
        rowHeight: 40,
        recline: 20,
        size: [ 77, 112, 99 ],
        // the width of one armrest (to fill out a row)
        armrestWidth: 15
    }
};
