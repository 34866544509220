export const ErrorCodes =
{
    // Seats
    SeatsTooWideForAisle: 1001,
    SeatsTooBigForRoom: 1002,
    SeatsTooFar: 1004,
    SeatsTooNear: 1005,

    // Projector
    ProjectorTooFar: 2001,
    ProjectorTooNear: 2002,

    // Screen
    ScreenTooSmall: 3001,
    ScreenTooLarge: 3002,

    // Ceiling speakers
    CeilingSpeakersTooLow: 4001,
    CeilingSpeakersTooCloseToWalls: 4002,
    CeilingSpeakersWrongAngle: 4003,
    CeilingSpeakersWrongAngleFront: 4004,
    CeilingSpeakersWrongAngleRear: 4005,

    // Front speakers
    FrontSpeakersBlockScreen: 5001,
    FrontSpeakersWrongAngle: 5002,
    FrontSpeakersTooCloseToWalls: 5003,

    // Side speakers
    SideSpeakersWrongAngle: 6001,

    // Rear speakers
    RearSpeakersWrongAngle: 6002,
    RearSpeakersTooCloseToSelf: 6003,
    RearSpeakersTooCloseToWall: 6004,
}