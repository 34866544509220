import {useMemo} from "react";
import {Euler, Matrix4, Vector3} from "three";

const v1 = new Vector3();
const v2 = new Vector3();
const mat = new Matrix4();
const yAxis = new Vector3(0, 1, 0);

export function useLookAt(source, target)
{
    return useMemo(() => {
        v1.fromArray(source);
        v2.fromArray(target);
        mat.lookAt(v2, v1, yAxis);
        return new Euler().setFromRotationMatrix(mat);
    }, [ source, target ]);
}