import React from "react";

import AudioConfigProvider from "./AudioConfigContext";
import SeatConfigProvider from "./SeatConfigContext";
import ScreenConfigProvider from "./ScreenConfigContext";
import RoomConfigProvider from "./RoomConfigContext";
import InteractionContextProvider from "./InteractionContext";
import ErrorContextProvider from "./ErrorContext";

/**
 * App data contains all the settings for the room setup
 * @constructor
 */
export default function AppData({children}) {
    
    return (
        <>
            <InteractionContextProvider>
                <RoomConfigProvider>
                    <ScreenConfigProvider>
                        <SeatConfigProvider>
                            <AudioConfigProvider>
                                <ErrorContextProvider>
                                    {children}
                                </ErrorContextProvider>
                            </AudioConfigProvider>
                        </SeatConfigProvider>
                    </ScreenConfigProvider>
                </RoomConfigProvider>
            </InteractionContextProvider>
        </>
    );
}
