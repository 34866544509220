import {useCallback} from "react";
import {MathUtils} from "three";
import {useRoomConfig} from "../RoomConfigContext";
import {useConstrainedState} from "../../hooks/useConstrainedState";

export function useSeatConstraint(initValue, numRows, config)
{
    const { depth } = useRoomConfig();
    const constrainDistance = useCallback(
        value => MathUtils.clamp(value, 150, depth - (numRows - 1) * config.rowDepth - config.recline),
        [numRows, config, depth]
    );
    return useConstrainedState(initValue, constrainDistance);
}