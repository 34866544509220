import {createContext, useContext} from "react";
import useTexture from "./hooks/useTexture";

const TextureContext = createContext(null);

export default function TextureContextProvider({children}) {
    const floorTexture = useTexture("./assets/textures/floor_carpet.jpg");
    const wallLEDTexture = useTexture("./assets/textures/wall_led.jpg");
    const wallAOTexture = useTexture("./assets/textures/wall_ao.jpg");
    const shadowTexture = useTexture("./assets/textures/shadow.jpg");
    const screenTextureDark = useTexture("./assets/textures/screen_dark.jpg");
    const screenTextureLight = useTexture("./assets/textures/screen_light.jpg");
    const podiumLEDTexture = useTexture("./assets/textures/podium_led.jpg");

    return (
        <TextureContext.Provider value={
            {
                wallLEDTexture, wallAOTexture,
                floorTexture, podiumLEDTexture,
                shadowTexture,
                screenTextureDark, screenTextureLight
            }
        }>
            {children}
        </TextureContext.Provider>
    );
}

export const useTextures = () => useContext(TextureContext);