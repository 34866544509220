import {useContextBridge} from "@react-three/drei";
import {AudioConfigContext} from "../../model/AudioConfigContext";
import {SeatConfigContext} from "../../model/SeatConfigContext";
import {RoomConfigContext, useRoomConfig} from "../../model/RoomConfigContext";
import {ScreenConfigContext} from "../../model/ScreenConfigContext";
import {Canvas} from "@react-three/fiber";
import Room from "./Room";
import Seats from "./Seats";
import Screen from "./Screen";
import {NoToneMapping} from "three";
import Speakers from "./Speakers";
import {InteractionContext} from "../../model/InteractionContext";
import {CameraControls} from "./camera/CameraControls";
import TextureContextProvider from "./TextureContext";

export default function View3D() {
    const ContextBridge = useContextBridge(RoomConfigContext, SeatConfigContext, AudioConfigContext, ScreenConfigContext, InteractionContext);
    const {height, depth, mainLightOn} = useRoomConfig();

    return (
        <Canvas camera={{far: 2000}} onCreated={({gl}) => {
            gl.toneMapping = NoToneMapping
        }}>
            <ContextBridge>
                <TextureContextProvider>
                    <pointLight position={[0, height * .5, -10]} args={[0xffffff, 6.0, 600.0, 2]} intensity={mainLightOn? 0.5 : 1.0}/>
                    <pointLight position={[0, height * .5, -depth * .5]} args={[0xffffff, 6.0, 600.0, 2]} intensity={mainLightOn? 4: 0}/>
                    <ambientLight color={0xa0a0d0} intensity={0.5}/>
                    <CameraControls/>
                    <Room/>
                    <Seats/>
                    <Screen/>
                    <Speakers/>
                </TextureContextProvider>
            </ContextBridge>
        </Canvas>
    );
}