import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {getAngleScore, getMaximumOffset} from "../utils/speakerUtils";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";
import {useSeatConfig} from "../SeatConfigContext";
import {getAngle} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useRearSpeakerValidator = (numRegularSpeakers, rearInCeiling, wallConfig, ceilingConfig, positions) => {
    const {width} = useRoomConfig();
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        const score = createScore();

        // no rear speakers in 5.1 (they're considered side speakers in code)
        if (numRegularSpeakers === 5)
            return score;

        // use the diagonal as the minimum offset to walls
        const size = rearInCeiling? wallConfig.size : ceilingConfig.size;
        const maxW = getMaximumOffset(size);
        // TODO: - 10 is too close to the wall: figure out true value
        const minDistToWall = 10;
        const minDistToCenter = maxW;
        const maxX = width * .5 - maxW - minDistToWall;

        // they're always symmetric, so just use the first speaker's x position
        const pos = positions[0].position;
        const x = pos[0];
        const z = pos[2] - primaryEarPosition[2];
        const angle = getAngle(x, z);

        updateScore(
            score,
            getAngleScore(angle, 142.5, 135, 150, 7.5)
        )

        if (x < minDistToCenter)
            updateScore(score, 3, ErrorCodes.RearSpeakersTooCloseToSelf);

        if (x > maxX) {
            updateScore(score, 3, ErrorCodes.RearSpeakersTooCloseToWall);
        }

        // TODO: Check angle scores

        return score;
    }, [width, wallConfig, ceilingConfig, positions, numRegularSpeakers, rearInCeiling, primaryEarPosition]);
}