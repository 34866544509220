import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useScreenConfig} from "../ScreenConfigContext";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useSeatValidator = (numRows, numSeats, distance, primarySeatDistance, config) => {
    const {width, depth} = useRoomConfig();
    const {screenSize} = useScreenConfig();

    return useMemo(() => {
        const d = distance + (numRows - 1) * config.rowDepth;
        const w = numSeats * config.size[0];
        const optimalDistance = screenSize * 1.5;
        const optimalTolerance = 100;
        const score = createScore();

        if (d > depth - config.recline + 0.01 || d < config.rowDepth - 0.01 || w > width)
            updateScore(score, 3, ErrorCodes.SeatsTooBigForRoom);

        const diff = primarySeatDistance - optimalDistance;

        if (diff > optimalTolerance)
            updateScore(score, 2, ErrorCodes.SeatsTooFar);
        else if (diff < -optimalTolerance)
            updateScore(score, 2, ErrorCodes.SeatsTooNear);
        else if (diff > optimalTolerance * .5)
            updateScore(score, 1, ErrorCodes.SeatsTooFar);
        else if (diff < -optimalTolerance * .5)
            updateScore(score, 1, ErrorCodes.SeatsTooNear);

        return score;
    }, [depth, width, config, numRows, numSeats, distance, primarySeatDistance, screenSize]);
}