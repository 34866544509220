import {createContext, useCallback, useContext, useRef, useState} from "react";

export const InteractionContext = createContext(null);
export const CameraMode = {
    Orbit: 0,
    TopDown: 1,
    Seat: 2
};

export default function InteractionContextProvider({children}) {

    const queryString = window.location.search;
    const UrlParams = new URLSearchParams(queryString);

    let defaultCamX = 300;
    let defaultCamY = -150;
    let defaultCam = CameraMode.Orbit;

    if (UrlParams.has('camx')) {
        defaultCamX = UrlParams.get('camx');
    }

    if (UrlParams.has('camy')) {
        defaultCamY = UrlParams.get('camy');
    }

    if (UrlParams.has('cam')) {
        if (UrlParams.get('cam') == 1) {
            defaultCam = CameraMode.TopDown;
        }
        else if (UrlParams.get('cam') == 2) {
            defaultCam = CameraMode.Seat;
        }
    }

    // Whether or not something in 3D is being dragged (should disable camera movement)
    const [isDragging, setIsDragging] = useState(false);
    // the current 3D camera mode
    const [cameraMode, setCameraMode] = useState(defaultCam);

    const [cameraX, setCameraX] = useState(defaultCamX);
    const [cameraY, setCameraY] = useState(defaultCamY);
    // whether or not something is loading (should display a loader if true)
    const [isLoading, setIsLoading] = useState(false);
    const loadCount = useRef(0);

    const startLoading = useCallback(() => setIsLoading(++loadCount.current > 0), [loadCount]);
    const endLoading = useCallback(() => setIsLoading(--loadCount.current > 0), [loadCount]);

    return (
        <InteractionContext.Provider value={{
            isDragging, setIsDragging,
            cameraMode, setCameraMode,
            cameraX, setCameraX,
            cameraY, setCameraY,
            isLoading, startLoading, endLoading,
        }}>
            {children}
        </InteractionContext.Provider>
    );
}

export const useInteractionContext = () => useContext(InteractionContext);
