import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useSeatConfig} from "../SeatConfigContext";
import {getMaximumOffset} from "../utils/speakerUtils";
import {getOppositeLength} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useSideSpeakers = (numSpeakers, config) => {
    const {width, depth} = useRoomConfig();
    const {primaryEarPosition, numRows, rowDepth, distance, primarySeatDistance} = useSeatConfig();

    return useMemo(() => {
        // use the diagonal as the minimum offset to walls
        const maxW = getMaximumOffset(config.size);
        const x = width * .5 - maxW;
        // center the speakers at 120cm (see spec)
        const y = 120 - config.size[1] * .5;
        let z, angle;
        // TODO: Base this on real specs
        // 5.1 must be behind seats, 7.1 parallel? Handle this using angles!
        // try to set them at 115deg for 5.1, 100 for 7.1

        // rear speakers
        if (numRows === 1) {
            const ang = (numSpeakers === 5 ? 115 : 100) - 90;
            z = -primaryEarPosition[2] + getOppositeLength(x, ang);
        }
        // true side speakers with multiple rows. must be aligned with middle row for optimal distribution
        else {
            // the offset between ear and seat
            const offset = primarySeatDistance - -primaryEarPosition[2];
            // apply this offset to the middle seat as well
            z = (numRows - 1) / 2 * rowDepth + distance - offset;
        }

        // true side speakers (when numSpeakers > 5) must not be angled
        angle = numSpeakers > 5? -Math.PI * .5 : -Math.atan2(x, z + primaryEarPosition[2]);

        z = Math.min(z, depth - maxW);
        z = -z;

        return [
            {
                position: [x, y, z],
                rotation: angle
            },
            {
                position: [-x, y, z],
                rotation: -angle
            }
        ];
    }, [depth, primaryEarPosition, numSpeakers, width, config, distance, numRows, rowDepth, primarySeatDistance]);
}