import {forwardRef, useMemo} from "react";

const ModelInstance = forwardRef(({object, ...props}, ref) =>
{
    const instance = useMemo(() => object?.clone(), [object]);

    if (!instance)
        return <group></group>;

    return (
        <primitive object={instance} {...props} ref={ref}>
        </primitive>
    );
});

export default ModelInstance;