import {useMemo} from "react";
import {getProjectorDistances} from "../utils/projectorUtils";
import {ScreenType} from "../../config/ScreenConfig";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";

// validators just return a score from 0 - 3 (good to worst)
export const useProjectorValidator = (config, screenSize, position) =>
{
    return useMemo(() => {
        if (config.type === ScreenType.UltraShortThrow) {
            return 0;
        }

        const dists = getProjectorDistances(config, screenSize);
        const distance = -position[2];
        const score = createScore();

        // TODO can use optimal distance (dists[2]) to return other scores?
        if (distance < dists[0])
            updateScore(score, 3, ErrorCodes.ProjectorTooNear);
        else if (distance > dists[1])
            updateScore(score, 3, ErrorCodes.ProjectorTooFar);

        return score;
    }, [config, screenSize, position]);

}