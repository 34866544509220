import {useMemo} from "react";

export function useClonedTexture(texture)
{
    return useMemo(() => {
        if (!texture) return null;
        const tex = texture.clone();
        tex.needsUpdate = true;
        return tex;
    }, [texture]);
}