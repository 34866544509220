import {BufferAttribute, BufferGeometry} from "three";

let geom;

export function usePodiumGeometry()
{
    if (!geom) {
        geom = new BufferGeometry();
        const positions = new Float32Array([
            // left
            -0.5, 1.0, 1.0,
            -0.5, 1.0, 0.0,
            -0.5, 0.0, 0.0,
            -0.5, 0.0, 1.0,

            // right
            0.5, 1.0, 0.0,
            0.5, 1.0, 1.0,
            0.5, 0.0, 1.0,
            0.5, 0.0, 0.0,

            // back
            -0.5, 1.0, 0.0,
            0.5, 1.0, 0.0,
            0.5, 0.0, 0.0,
            -0.5, 0.0, 0.0,

            // front
            0.5, 1.0, 1.0,
            -0.5, 1.0, 1.0,
            -0.5, 0.0, 1.0,
            0.5, 0.0, 1.0,

            // top
            0.5, 1.0, 0.0,
            -0.5, 1.0, 0.0,
            -0.5, 1.0, 1.0,
            0.5, 1.0, 1.0,
        ]);

        const uv = new Float32Array([
            // regular quads for sides
            0, 1, 1, 1, 1, 0, 0, 0,
            0, 1, 1, 1, 1, 0, 0, 0,
            0, 1, 1, 1, 1, 0, 0, 0,
            0, 1, 1, 1, 1, 0, 0, 0,

            // top shouldn't have leds, so flatten uv
            0, 1, 1, 1, 0, 1, 1, 1
        ]);

        const normals = new Float32Array([
            // left
            -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0,

            // right
            1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,

            // back
            0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1,
            // front
            0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1,
            // top
            0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0
        ]);

        const indices = [
            0, 1, 2, 0, 2, 3,
            4, 5, 6, 4, 6, 7,
            8, 9, 10, 8, 10, 11,
            12, 13, 14, 12, 14, 15,
            16, 17, 18, 16, 18, 19
        ];

        geom.setAttribute("position", new BufferAttribute(positions, 3));
        geom.setAttribute("normal", new BufferAttribute(normals, 3, true));
        geom.setAttribute("uv", new BufferAttribute(uv, 2, true));
        geom.setIndex(indices);
    }

    return geom;
}