import {useEffect, useState} from "react";

export const useConstrainedState = (initValue, constrain) =>
{
    const [ value, setValue ] = useState(initValue);

    // only if the callback changes, the state must be reapplied, NOT when value changes (so as not to loop infinitely)
    useEffect(() => {
        setValue(constrain(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constrain]);

    return [ value, val => setValue(constrain(val)) ];
}