import React, {createContext, useCallback, useContext, useState} from "react";
import {useThree} from "@react-three/fiber";

export const RoomConfigContext = createContext(null);

export const FloorColors = {
    Light: "#eeeef0",
    Dark: "#101018",
    Red: "#800000"
};

export const AisleMode = {
    // these values are made to be a bit mask!
    Left: 1,
    Right: 2,
    Both: 3
}

export default function RoomConfigProvider({children}) {

    let defaultWidth = 400;
    let defaultHeight = 250;
    let defaultDepth = 500;
    let defaultFloorColor = FloorColors.Dark;
    let defaultWallColor = "#101020";
    let defaultCeilingColor = "#202028";
    let defaultAisleWidth = 50;
    let defaultAisleMode = AisleMode.Both;
    let defaultFloorLedsOn = true;

    const queryString = window.location.search;
    const UrlParams = new URLSearchParams(queryString);

    if (UrlParams.has('configId') && UrlParams.has('userMail')) {
        // Do something
    }

    const [width, setWidth] = useState(defaultWidth);
    const [height, setHeight] = useState(defaultHeight);
    const [depth, setDepth] = useState(defaultDepth);
    const [floorColor, setFloorColor] = useState(defaultFloorColor);
    const [wallColor, setWallColor] = useState(defaultWallColor);
    const [ceilingColor, setCeilingColor] = useState(defaultCeilingColor);
    const [aisleWidth, setAisleWidth] = useState(defaultAisleWidth);
    const [aisleMode, _setAisleMode] = useState(defaultAisleMode);
    const [mainLightOn, setMainLightOn] = useState(true);
    const [floorLedsOn, setFloorLedsOn] = useState(defaultFloorLedsOn);
    const setAisleMode = useCallback(v => _setAisleMode(parseInt(v)), []);

    return (
        <RoomConfigContext.Provider value={{
            width, setWidth,
            height, setHeight,
            depth, setDepth,
            aisleWidth, setAisleWidth,
            floorColor, setFloorColor,
            wallColor, setWallColor,
            ceilingColor, setCeilingColor,
            mainLightOn, setMainLightOn,
            floorLedsOn, setFloorLedsOn,
            aisleMode, setAisleMode
        }}>
            {children}
        </RoomConfigContext.Provider>
    );
}

export const useRoomConfig = () => useContext(RoomConfigContext);
