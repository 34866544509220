import {useConeGeometry} from "./geometry/useConeGeometry";
import {useMemo} from "react";
import {useConeMaterial} from "./materials/useConeMaterial";

export function SpeakerCone({color = 0x5080ff, opacity = 0.25, angle = 90, size = 300, ...props})
{
    const [scaleR, scaleH] = useMemo(() => {
        const ang = angle * Math.PI / 360;
        const scaleY = Math.sin(ang);
        const scaleX = Math.cos(ang);
        return [scaleY * size, scaleX * size];
    }, [angle, size]);
    const geometry = useConeGeometry();
    const material = useConeMaterial(color, opacity);

    return (
        <mesh geometry={geometry} {...props} scale={[scaleR, scaleR, scaleH]} material={material}/>
    );
}