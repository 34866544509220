import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useSeatConfig} from "../SeatConfigContext";
import {getMaximumOffset} from "../utils/speakerUtils";
import {getOppositeLength} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useFrontSpeakers = (config) => {
    const {width} = useRoomConfig();
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        // use the diagonal as the minimum offset to walls
        const maxW = getMaximumOffset(config.size) * .5;
        const maxX = width * .5 - maxW;
        const z = -maxW;
        const d = -primaryEarPosition[2] - maxW;
        // try the widest angle (30) to prevent overlap with screen
        let x = getOppositeLength(d, 30);
        x = Math.min(x, maxX);

        const angle = -Math.atan2(x, -d);

        return [
            {
                position: [x, 0, z],
                rotation: angle
            },
            {
                position: [-x, 0, z],
                rotation: -angle
            }
        ];
    }, [primaryEarPosition, width, config]);
}