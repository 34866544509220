import {useEffect, useMemo} from "react";
import {Color, NormalBlending, ShaderMaterial} from "three";

const vertexShader = `
uniform vec3 color;
uniform float opacity;

varying vec4 vColor;

void main()
{
    // todo: add vertex color
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    vColor = vec4(color, opacity * (1.0 - position.z)); 
}

`;

const fragmentShader = `
varying vec4 vColor;

void main()
{
    gl_FragColor = vColor;
}
`;

export function useConeMaterial(color, opacity)
{
    const material = useMemo(() => {
        return new ShaderMaterial({
            vertexShader, fragmentShader,
            uniforms: {
                color: {
                    value: new Color()
                },
                opacity: {
                    value: 1
                }
            },
            transparent: true,
            depthWrite: false,
            blending: NormalBlending,
            // side: DoubleSide
        });
    }, []);

    useEffect(() => {
        material.uniforms.color.value.set(color);
    }, [color, material]);

    useEffect(() => {
        material.uniforms.opacity.value = opacity;
    }, [opacity, material]);

    return material;
}