import {useAudioConfig} from "../../model/AudioConfigContext";
import {MeshLambertMaterial} from "three";
import {useMemo} from "react";
import useGLTF from "./hooks/useGLTF";
import Speaker from "./Speaker";
import CeilingSpeakerMaterial from "./materials/CeilingSpeakerMaterial";

export default function Speakers() {
    const {
        ceilingPositions,
        frontPositions,
        sidePositions,    // used by 5.1 and 7.1
        rearPositions,    // used by 7.1
        frontSpeakersScore,
        ceilingSpeakersScore,
        sideSpeakersScore,
        rearSpeakersScore,
        rearInCeiling,
        subwooferPosition,
        centerPosition,
        ceiling,
        front,
        surround,
        subwoofer,
        center
    } = useAudioConfig();

    const ceilingModel = useGLTF(ceiling.gltf, CeilingSpeakerMaterial);
    const frontModel = useGLTF(front.gltf, MeshLambertMaterial);
    const surroundModel = useGLTF(surround.gltf, MeshLambertMaterial);
    // use duplicates for rear so they can be coloured individually
    const surroundRearModel = useGLTF(surround.gltf, MeshLambertMaterial);
    const ceilingRearModel = useGLTF(ceiling.gltf, CeilingSpeakerMaterial);
    const rearModel = useMemo(() => rearInCeiling ? ceilingRearModel : surroundRearModel, [rearInCeiling, surroundRearModel, ceilingRearModel])
    const subwooferModel = useGLTF(subwoofer.gltf, MeshLambertMaterial);
    const centerModel = useGLTF(center.gltf, MeshLambertMaterial);

    return (
        <>
            <Speaker model={subwooferModel} position={subwooferPosition.position}
                     rotation={[0, subwooferPosition.rotation, 0]}
                     config={subwoofer}
            />
            <Speaker model={centerModel} position={centerPosition.position}
                     speakerOffset={center.speakerOffset}
                     config={center}
                     rotation={[0, centerPosition.rotation, 0]}/>
            {
                frontPositions.map((elm, i) =>
                    <Speaker model={frontModel} position={elm.position}
                             rotation={[0, elm.rotation, 0]}
                             score={frontSpeakersScore}
                             config={front}
                             key={i}
                    />
                )
            }
            {
                sidePositions.map((elm, i) =>
                    <Speaker model={surroundModel} position={elm.position} rotation={[0, elm.rotation, 0]}
                             score={sideSpeakersScore}
                             config={surround}
                             key={i}/>
                )
            }
            {
                rearPositions.map((elm, i) =>
                    <Speaker model={rearModel} position={elm.position} rotation={[0, elm.rotation, 0]}
                             score={rearSpeakersScore}
                             config={rearInCeiling ? ceiling : surround}
                             key={i}/>
                )
            }
            {
                ceilingPositions.map((elm, i) =>
                    <Speaker model={ceilingModel} position={elm.position} key={i} score={ceilingSpeakersScore}
                             config={ceiling} />
                )
            }
        </>
    );
}