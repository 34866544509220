import * as React from 'react'
import {useFrame, useThree} from '@react-three/fiber'
import {LookControls} from "./LookControls";

export const LookAroundControls = React.forwardRef(
    ({camera, ...restProps}, ref) => {
        const invalidate = useThree(({invalidate}) => invalidate);
        const defaultCamera = useThree(({camera}) => camera);
        const gl = useThree(({gl}) => gl);
        const explCamera = camera || defaultCamera;
        const controls = React.useMemo(() => new LookControls(explCamera), [explCamera]);

        useFrame(() => controls.update());

        React.useEffect(() => {
            const callback = () => {
                invalidate();
            }

            controls.connect(gl.domElement);

            controls.addEventListener('change', callback);
            return () => {
                controls.removeEventListener('change', callback);
                controls.dispose();
            }
        }, [controls, invalidate, gl.domElement]);

        return <primitive ref={ref} object={controls} {...restProps} />
    }
)