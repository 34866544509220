import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useSeatConstraint} from "./rules/useSeatConstraint";
import {SeatConfig} from "../config/SeatConfig";
import {useSeatValidator} from "./rules/useSeatValidator";
import {AisleMode, useRoomConfig} from "./RoomConfigContext";
import {loadedConfig} from "./InteractionContext";

export const SeatConfigContext = createContext(null);

export default function SeatConfigProvider({children}) {

    const [config, setConfig] = useState(SeatConfig.cinemove);
    const [modelID, setModelID] = useState(0);

    const [numRows, setNumRows] = useState(1);
    const [numSeats, setNumSeats] = useState(3);
    const [preferredRow, setPreferredRow] = useState(0);
    const {width, aisleMode, aisleWidth, setAisleWidth} = useRoomConfig();

    // distance between first chair and screen
    const [distance, setDistance] = useSeatConstraint(375, numRows, config);

    useEffect(() => {
        setModelID(0);
    }, [config]);

    const gltf = useMemo(() => config.models[modelID]?.gltf, [config, modelID]);

    // distance between preferred seat and screen (derived value)
    const primarySeatDistance = useMemo(() => {
        return config.rowDepth * preferredRow + distance;
    }, [config.rowDepth, preferredRow, distance]);

    const numAisles = aisleMode === AisleMode.Both? 2 : 1;
    const podiumPadding = 10;
    const seatWidth = useMemo(
        () => config.size[0] * numSeats + config.armrestWidth,
        [config, numSeats]
    );

    const maxAisleWidth = useMemo(
        () => (width - seatWidth - 2 * podiumPadding) / 2
    , [width, numAisles, seatWidth, podiumPadding]);

    const podiumWidth = useMemo(() => {
        return seatWidth + podiumPadding * 2 + aisleWidth * numAisles;
    }, [seatWidth, aisleWidth, numAisles]);

    useEffect(() => { setAisleWidth(Math.min(aisleWidth, maxAisleWidth)); }, [aisleWidth, setAisleWidth, maxAisleWidth]);

    const podiumOffset = useMemo(() => {
        if (aisleMode === AisleMode.Both)
            return 0;

        if (aisleMode === AisleMode.Left)
            return aisleWidth * .5;

        return -aisleWidth * .5;
    }, [aisleWidth, aisleMode]);

    const setConfigID = useCallback(id => setConfig(SeatConfig[id]), []);
    const { score, errorCodes } = useSeatValidator(numRows, numSeats, distance, primarySeatDistance, config);

    const primaryEarPosition = useMemo(() => {
        return [0, config.rowHeight * preferredRow + 120, -primarySeatDistance + 20];
    }, [config.rowHeight, preferredRow, primarySeatDistance]);

    return (
        <SeatConfigContext.Provider value={{
            numRows, setNumRows,
            numSeats, setNumSeats,
            preferredRow, setPreferredRow,
            distance, setDistance,
            primarySeatDistance, primaryEarPosition,
            ...config, setConfigID,
            modelID, setModelID,
            score, errorCodes,
            podiumWidth, podiumOffset,
            maxAisleWidth,
            gltf
        }}>
            {children}
        </SeatConfigContext.Provider>
    );
}

export const useSeatConfig = () => useContext(SeatConfigContext);
