/**
 * This estimates the offset an object should be at (from the wall fe) based on its size.
 */
export function getMaximumOffset(size)
{
    return Math.sqrt(size[0] * size[0] + size[1] * size[1]) * .5;
}

/**
 * Calculates the score value for a speaker angle.
 * @param angle The current angle of the speaker.
 * @param idealAngle The ideal angle the speaker should be at.
 * @param minAngle The minimum allowed angle.
 * @param maxAngle The maximum allowed angle.
 * @param idealRange The range of the ideal angle. If the difference between the real angle and the ideal angle is
 * within this range, it will still be considered valid.
 */
export function getAngleScore(angle, idealAngle, minAngle, maxAngle, idealRange = 2)
{
    const diff = angle - idealAngle;

    if (Math.abs(diff) < idealRange)
        return 0;

    // too wide
    if (diff < minAngle || diff > maxAngle)
        return 2;

    // still acceptable
    return 1;
}