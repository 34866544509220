import {useMemo} from "react";
import {useRoomConfig} from "../RoomConfigContext";
import {useSeatConfig} from "../SeatConfigContext";
import {MathUtils} from "three";
import {getOppositeLength} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useCeilingSpeakers = (count, config, frontPositions) => {
    const {depth, height} = useRoomConfig();
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        // triangle between ear, ceiling, and speaker
        const h = height - primaryEarPosition[1];
        let centerZ = primaryEarPosition[2];
        let offsetZ = 0;

        if (count === 2) {
            // there should optimally be a 10 degree angle
            centerZ += getOppositeLength(h, 10);
        }
        else if (count === 4) {
            // speakers should be front/back at 35 degrees
            offsetZ = getOppositeLength(h, 35);
        }

        const positions = [];
        const maxW = config.size[2] * .5;
        const minZ = -depth + maxW;

        for (let i = 0; i < count; ++i) {
            const x = frontPositions[i % 2].position[0];
            const y = height;
            let z = centerZ;

            if (count === 4) {
                z += i >= 2? -offsetZ : offsetZ;
            }

            z = MathUtils.clamp(z, minZ, -maxW);
            positions[i] = { position: [x, y, z] };
        }

        return positions;
    }, [count, primaryEarPosition, depth, height, frontPositions, config.size]);
}
