import {useMemo} from "react";
import {getAngleScore} from "../utils/speakerUtils";
import {useSeatConfig} from "../SeatConfigContext";
import {createScore, updateScore} from "../utils/score";
import {ErrorCodes} from "../ErrorCodes";
import {getAngle} from "../utils/trig";

// ceiling speakers don't use the regular constraint / validation ruleset. Instead, their positions are entirely
// calculated before validation
export const useSideSpeakerValidator = (numSpeakers, config, positions) => {
    const {primaryEarPosition} = useSeatConfig();

    return useMemo(() => {
        const score = createScore();

        // they're always symmetric, so just use the first speaker's position
        const pos = positions[0].position;
        const offsetZ = -pos[2] + primaryEarPosition[2];
        const angle = getAngle(offsetZ, pos[0]) + 90;
        let minAngle;
        let maxAngle;

        if (numSpeakers === 5) {
            minAngle = 110;
            maxAngle = 120;
        } else if (numSpeakers === 7) {
            minAngle = 90;
            maxAngle = 110;
        }

        updateScore(
            score,
            // allow entire range
            getAngleScore(angle, (minAngle + maxAngle) * .5, minAngle, maxAngle, (maxAngle - minAngle) * .5),
            ErrorCodes.SideSpeakersWrongAngle
        );

        return score;
    }, [positions, numSpeakers, primaryEarPosition]);
}