/**
 * Create a new empty and valid score object which can be returned from a validator hook.
 */
export function createScore()
{
    return {
        score: 0,
        errorCodes: []
    }
}

/**
 * Updates an exisiting score object.
 * @param currentScore The score object to update.
 * @param newScore The new score value. If this value is less than the old score value, the value will remain the same.
 * @param errorCode The error code to be added if the new score value is not 0.
 */
export function updateScore(currentScore, newScore, errorCode)
{
    currentScore.score = Math.max(currentScore.score, newScore);
    if (newScore > 0)
        currentScore.errorCodes.push(errorCode);
}