import {useAspectRatio} from "./useAspectRatio";
import {useMemo} from "react";
import {useRoomConfig} from "../model/RoomConfigContext";

/**
 * A hook to return the maximum screen size based on the room size and screen config.
 */
export const useScreenLimits = (aspectRatio, screenRange, screenFloorDistance) => {
    const { width, height } = useRoomConfig();
    const aspect = useAspectRatio(aspectRatio);

    return useMemo(() => {
        let minW = 100;
        let maxW = width;
        const maxH = height - screenFloorDistance;

        if (screenRange) {
            minW = Math.max(minW, screenRange[0]);
            maxW = Math.min(maxW, screenRange[1]);
        }

        maxW = Math.min(maxW, maxH * aspect);

        return [minW, maxW];
    }, [screenRange, width, height, aspect, screenFloorDistance])
}