import React, {useEffect, useMemo} from "react";
import {
    AdditiveBlending,
    Box3,
    GammaEncoding,
    LinearEncoding,
    MeshBasicMaterial,
    MeshLambertMaterial,
    Vector3
} from "three";
import {useRoomConfig} from "../../model/RoomConfigContext";
import {useScreenConfig} from "../../model/ScreenConfigContext";
import {ScreenType} from "../../config/ScreenConfig";
import useGLTF from "./hooks/useGLTF";
import {useTextures} from "./TextureContext";
import {useClonedTexture} from "./hooks/useClonedTexture";
import {useWallGeometry} from "./geometry/useWallGeometry";
import {useLedStripGeometry} from "./geometry/useLedStripGeometry";

function useWallTexture(color, aoMap, emissiveMap)
{
    return useMemo(() => new MeshLambertMaterial({
        color,
        emissiveIntensity: 1.0,
        emissiveMap,
        emissive: 0xffffff,
        aoMap
    }), [color, emissiveMap, aoMap]);
}

export default function Room() {
    const ledStripHeight = 5;
    const ledStripOffset = 5;
    const {width, height, depth, floorColor, wallColor, ceilingColor, floorLedsOn} = useRoomConfig();
    const {type} = useScreenConfig();
    const cupboardModel = useGLTF("./assets/models/Meubel_LowTable001/Meubel_LowTable001.gltf", MeshLambertMaterial);
    const cupboardPosition = useMemo(() => {
        const pos = new Vector3();
        if (cupboardModel) {
            const bounds = new Box3();
            bounds.setFromObject(cupboardModel.scene);
            pos.z = -bounds.max.z;
        }
        return pos
    }, [cupboardModel]);

    const {floorTexture, wallLEDTexture, wallAOTexture} = useTextures();
    const wallBackLEDTexture = useClonedTexture(wallLEDTexture);
    const wallSideLEDTexture = useClonedTexture(wallLEDTexture);
    const backMaterial = useWallTexture(wallColor, wallAOTexture, wallBackLEDTexture);

    const frontMaterial = useMemo(() => new MeshLambertMaterial({
        color: wallColor,
        aoMap: wallAOTexture
    }), [wallColor, wallAOTexture]);
    const sideMaterial = useWallTexture(wallColor, wallAOTexture, wallSideLEDTexture);
    const {podiumLEDTexture} = useTextures();

    const ledStripGeometry = useLedStripGeometry();
    const ledTexture = useClonedTexture(podiumLEDTexture);
    useEffect(() => {
        if (!ledTexture) return;
        ledTexture.repeat.y = .1;
        ledTexture.outputEncoding = LinearEncoding;
        ledTexture.encoding = GammaEncoding;
    }, [ledTexture]);

    const ledMaterial = useMemo(() => {
        return new MeshBasicMaterial({
            color: 0x0a0a80,
            map: ledTexture,
            blending: AdditiveBlending,
            polygonOffset: true,
            polygonOffsetUnits: -10
        });
    }, [ledTexture]);

    const floorMaterial = useMemo(() => new MeshLambertMaterial({
        map: floorTexture,
        color: floorColor,
        aoMap: wallAOTexture
    }), [floorTexture, floorColor, wallAOTexture]);
    const ceilingMaterial = useMemo(() => new MeshLambertMaterial({
        color: ceilingColor,
        aoMap: wallAOTexture
    }), [ceilingColor, wallAOTexture]);
    const wallGeometry = useWallGeometry();

    useEffect(() => {
            if (!floorTexture) return;
            floorTexture.repeat.set(width / 50, depth / 50);
        },
        [floorTexture, width, depth]);

    useEffect(() => {
        if (!wallSideLEDTexture) return;
        wallSideLEDTexture.repeat.set(depth / 100, 1);
    }, [wallSideLEDTexture, depth]);

    useEffect(() => {
        if (!wallBackLEDTexture) return;
        wallBackLEDTexture.repeat.set(width / 100, 1);
    }, [wallBackLEDTexture, width]);

    return (
        <>
            {/*front wall*/}
            <mesh scale={[width, height, 1]} rotation={[0, Math.PI, 0]}
                  material={frontMaterial} geometry={wallGeometry}/>
            {/*back wall*/}
            <mesh scale={[width, height, 1]} position={[0, 0, -depth]}
                  material={backMaterial} geometry={wallGeometry}/>
            {/* left wall (use negative scale so tiling textures stays fixed to the front wall */}
            <mesh scale={[depth, height, -1]} position={[width * .5, 0, -depth * .5]}
                  rotation={[0, Math.PI * .5, 0]}
                  material={sideMaterial} geometry={wallGeometry}/>
            {/* right wall */}
            <mesh scale={[depth, height, 1]} position={[-width * .5, 0, -depth * .5]}
                  rotation={[0, Math.PI * .5, 0]}
                  material={sideMaterial} geometry={wallGeometry}/>
            {/*floor*/}
            <mesh scale={[width, depth, 1]} position={[0, 0, -depth * .5]} rotation={[-Math.PI * .5, 0, 0]}
                  material={floorMaterial}>
                <planeBufferGeometry args={[1, 1]}/>
            </mesh>
            {/*ceiling*/}
            <mesh scale={[width, depth, 1]} position={[0, height, -depth * .5]} rotation={[Math.PI * .5, 0, 0]}
                  material={ceilingMaterial}>
                <planeBufferGeometry args={[1, 1]}/>
            </mesh>
            {/* Floor leds */}
            <mesh visible={floorLedsOn} scale={[width, ledStripHeight, depth]} geometry={ledStripGeometry}
                  position={[0, ledStripOffset, 0]}
                  material={ledMaterial}/>
            {
                cupboardModel && type === ScreenType.UltraShortThrow ?
                    <primitive object={cupboardModel.scene} position={cupboardPosition}/> : <></>
            }
        </>
    );
}