import {createContext, useContext, useMemo} from "react";
import {useSeatConfig} from "./SeatConfigContext";
import {useScreenConfig} from "./ScreenConfigContext";
import {useAudioConfig} from "./AudioConfigContext";

export const ErrorContext = createContext(null);

export default function ErrorContextProvider({children}) {
    const {errorCodes: seatErrorCodes} = useSeatConfig();
    const {projectorErrorCodes, screenErrorCodes} = useScreenConfig();
    const {
        ceilingSpeakersErrorCodes,
        frontSpeakersErrorCodes,
        sideSpeakersErrorCodes,
        rearSpeakersErrorCodes
    } = useAudioConfig();

    const codes = [
        seatErrorCodes, projectorErrorCodes, screenErrorCodes,
        ceilingSpeakersErrorCodes, frontSpeakersErrorCodes, sideSpeakersErrorCodes, rearSpeakersErrorCodes
    ];

    const errors = useMemo(() =>
            [].concat(...codes),
        // eslint-disable-next-line
        codes
    );
    return (
        <ErrorContext.Provider value={{errors}}>
            {children}
        </ErrorContext.Provider>
    );
}

export const useErrorContext = () => useContext(ErrorContext);