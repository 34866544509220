import {useErrorContext} from "../../model/ErrorContext";
import {ErrorCodes} from "../../model/ErrorCodes";
import {useMemo} from "react";

export default function ErrorMessages()
{
    const { errors } = useErrorContext();
    const messages = useMemo(() => {
        const messages = {};

        messages[ErrorCodes.SeatsTooBigForRoom] = "De zetels passen niet in de kamer.";
        messages[ErrorCodes.SeatsTooWideForAisle] = "De zetels zijn te breed voor de doorgang.";
        messages[ErrorCodes.SeatsTooFar] = "De primaire zitpositie is te ver van het scherm.";
        messages[ErrorCodes.SeatsTooNear] = "De primaire zitpositie is te dicht bij het scherm.";

        messages[ErrorCodes.ProjectorTooFar] = "De projector staat te ver van het scherm.";
        messages[ErrorCodes.ProjectorTooNear] = "De projector staat te dicht bij het scherm.";

        messages[ErrorCodes.ScreenTooLarge] = "Het scherm is te groot.";
        messages[ErrorCodes.ScreenTooSmall] = "Het scherm is te klein.";

        messages[ErrorCodes.CeilingSpeakersTooLow] = "De plafond luidsprekers staan te laag ten opzichte van de primaire zitpositie.";
        messages[ErrorCodes.CeilingSpeakersTooCloseToWalls] = "De plafond luidsprekers staan te dicht bij de muur.";
        messages[ErrorCodes.CeilingSpeakersWrongAngle] = "Er is niet voldoende ruimte voor de plafond luidsprekers.";
        messages[ErrorCodes.CeilingSpeakersWrongAngleFront] = "Er is niet voldoende ruimte voor de voorste plafond luidsprekers.";
        messages[ErrorCodes.CeilingSpeakersWrongAngleRear] = "Er is niet voldoende ruimte voor de achterste plafond luidsprekers.";

        messages[ErrorCodes.FrontSpeakersBlockScreen] = "Luidsprekers vooraan blokkeren het scherm.";
        messages[ErrorCodes.FrontSpeakersWrongAngle] = "Luidspreker vooraan staat niet in een ideale hoek.";
        messages[ErrorCodes.FrontSpeakersTooCloseToWalls] = "Luidsprekers vooraan staan te dicht bij de muur.";

        messages[ErrorCodes.SideSpeakersWrongAngle] = "De zijdelingse speakers staan niet in een ideale hoek.";

        messages[ErrorCodes.RearSpeakersWrongAngle] = "Er is niet voldoende ruimte voor de achterste luidsprekers.";
        messages[ErrorCodes.RearSpeakersTooCloseToSelf] = "Luidsprekers achteraan staan te dicht bij elkaar.";
        messages[ErrorCodes.RearSpeakersTooCloseToWall] = "Luidsprekers achteraan staan te dicht bij de muur.";

        return messages;
    },  []);
    return <div><h3>Waarschuwingen</h3>
        {
            errors.map((elm, i) =>
                <p key={i}>{ messages[elm] }</p>
            )
        }
    </div>
}
