import {useRoomConfig} from "../RoomConfigContext";
import {useMemo} from "react";
import {getProjectorDistances} from "../utils/projectorUtils";
import {ScreenType} from "../../config/ScreenConfig";

export const useProjectorPosition = (config, screenSize) => {
    const {height, depth} = useRoomConfig();

    return useMemo(() => {
        const dists = getProjectorDistances(config, screenSize);
        const roomEdge = depth - config.size[2];
        const distance = Math.min(dists[2], roomEdge);
        // use cupboard height
        const y = config.type === ScreenType.UltraShortThrow? 35 : height;
        return {
            position: [0, y, -distance],
            rotation: config.type === ScreenType.UltraShortThrow? Math.PI : 0
        };
    }, [height, depth, screenSize, config]);
}