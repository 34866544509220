import {useEffect, useRef, useState} from "react";
import {useInteractionContext} from "../../../model/InteractionContext";
import {RepeatWrapping, TextureLoader} from "three";

export default function useTexture(file) {
    const loader = useRef(null);
    const [asset, setAsset] = useState(null);
    const {startLoading, endLoading} = useInteractionContext();

    useEffect(() => {
        if (loader.current)
            loader.current.active = false;

        if (!file) return;

        startLoading();

        const texLoader = new TextureLoader();
        loader.current = texLoader;
        // hack to intercept loads (shouldn't occur with loader)
        texLoader.active = true;
        texLoader.load(file, asset => {
            if (!texLoader.active)
                return;

            asset.wrapS = RepeatWrapping;
            asset.wrapT = RepeatWrapping;
            setAsset(asset);

            endLoading();
        }, null, e => {
            console.log("Error loading texture " + file);
            console.log(e);
            endLoading();
        });
        // eslint-disable-next-line
    }, [file, endLoading, startLoading]);

    return asset;
}