import {useMemo, useRef} from "react";
import {useInteractionContext} from "../../../model/InteractionContext";
import {Vector3} from "three";


export const usePlanarDrag = (plane, onDrag, onStart, onStop) => {
    const active = useRef(false);
    const startPoint = useMemo(() => new Vector3(), []);
    const delta = useMemo(() => new Vector3(), []);
    const {setIsDragging} = useInteractionContext();

    return useMemo(() => ({
        onPointerDown: event => {
            event.stopPropagation();
            event.target.setPointerCapture(event.pointerId);
            active.current = true;
            // We don't want the camera to move while we're dragging, toggle it off
            setIsDragging(true);
            event.ray.intersectPlane(plane, startPoint);
            if (onStart) onStart(startPoint);
        },
        onPointerUp: event => {
            event.stopPropagation();
            event.target.releasePointerCapture(event.pointerId);
            active.current = false;
            // Drag has concluded, toggle the controls on again
            setIsDragging(false);
            if (onStop) onStop();
        },
        onPointerMove: event => {
            if (active.current) {
                event.stopPropagation();
                event.ray.intersectPlane(plane, delta);
                delta.sub(startPoint);
                onDrag(delta);
            }
        }
    }), [onStart, onStop, onDrag, delta, plane, setIsDragging, startPoint]);
}